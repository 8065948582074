@import "src/variables";
@import "src/scenes/subject/certificates/certificates-common-styles.module";

.edocs-section {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px $color-grey-300 solid;

  gap: 80px 160px;

  .edocs-section__subsection {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    .edocs-section__subsection-body {
      display: flex;
      align-items: center;
      gap: 40px;
      flex-wrap: wrap;

      img {
        width: 120px;
        height: 165px;
      }

      .body-footer {
        width: 100%;

        .body-footer__title {
          font: $font-regular-12;
          color: $color-grey-600;
        }

        .body-footer__value {
          color: $color-grey-600;
        }
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .edocs-section {
    flex-direction: column;
    align-items: flex-start;

    gap: 40px 80px;

    .edocs-section__subsection {
      width: 100%;
      padding: 0;

      .edocs-section__subsection-body {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }
  }
}