@import "src/variables";

.with-navigation-layout {
  .with-navigation-layout__content {
    padding: 77px 80px;
  }

  .navigation-tabs {
    position: sticky;
    padding: 0 20px;
    height: 100%;
    background: $color-green_200;
    z-index: $navigation-z-index;
    overflow-y: hidden;

    &-official {
      background: $color-grey-400;
    }

    nav {
      overflow-x: auto;

      menu {
        &>* {
          padding-top: 10px;
          font: $font-regular-16;
        }
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .with-navigation-layout {

    .with-navigation-layout__content {
      padding: 77px 80px;
    }

    .navigation-tabs {
      padding: 0 16px;

      nav {
        menu {
          &>* {
            font: $font-regular-12;
          }
        }
      }
    }
  }
}