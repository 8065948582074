@import "src/variables";

.blank-page {
  display: flex;
  flex-direction: column;
  // padding: 24px 80px 300px 80px;
  gap: 17px;

  .blank-page__image {
    width: 50px;
    height: 50px;
  }

  .blank-page__title {
    font: $font-regular-20;
  }
}

@media (max-width: $media-max-width-lg) {
  .blank-page {
    padding: 40px 16px 300px 16px;
    gap: 12px;

    .blank-page__title {
      font: $font-regular-16;
    }
  }
}
