.container {
  margin: auto;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.7);

  &.container-child-preloader {
    position: fixed;
    background: transparent;
    left: 0;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
  }

  &.is-absolute {
    position: absolute;
  }

  &.is-small {
    padding: 0;

    .icon {
      width: 40px;
      height: 40px;
    }
  }

  &.is-big {
    .icon {
      width: 120px;
      height: 120px;
    }
  }
}

.icon {
  width: 90px;
  height: 90px;
  margin: auto;
}