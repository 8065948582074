@import "src/variables";

.applicant-section {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  // grid-template-rows: 3fr 3fr;
  grid-column-gap: 120px;
  grid-gap: 20px;

  &__official {
    grid-template-columns: 3fr 1fr 4fr 1fr;
  }
}

@media (max-width: $media-max-width-lg) {
  .applicant-section {
    grid-template-columns: 1fr;

    & > *:not(:first-child) {
      margin-top: 15px;
    }
  }
}
