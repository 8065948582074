@import "src/variables";

.blank-page {
  &__container {
    padding: 0 20px;
    margin-top: 150px;
    margin-bottom: 300px;
  }

  &__content {
    flex-direction: row;
    align-items: center;
  }
}

.specify-usage__title {
  margin-top: 47px;
  margin-bottom: 47px;
  font: $font-regular-32;
}

@media (max-width: $media-max-width-md) {
  .blank-page {
    &__container {
      padding: 0 16px;
      margin-top: 130px;
    }

    &__content {
      padding: 0;
    }
  }
}