@import "src/variables";

.add-trusted-doc-wrapper {
  .add-trusted-doc {
    .add-trusted-doc__reminder {
      margin-bottom: 40px;
    }

    .add-trusted-doc__form {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .add-trusted-doc__form-info {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .add-trusted-doc__info-input {
          display: flex;
          gap: 50px;

          &> :last-child {
            input {
              text-overflow: ellipsis;
            }
          }

          &> :first-child {
            max-width: 550px;
          }

          input {
            min-width: max-content;
          }
        }
      }

      .add-trusted-doc__form-files {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }
  }

  .form-btns {
    display: flex;
    gap: 20px;
  }

  .form-btn {
    max-width: 154px;
  }

  .invoice-btn {
    margin-top: 40px;
  }
}

.add-trusted-doс__reminder {
  margin-bottom: 60px;
}

@media (max-width: $media-max-width-lg) {
  .add-trusted-doc-wrapper {
    .add-trusted-doc {
      .add-trusted-doc__form {
        gap: 30px;

        .add-trusted-doc__form-info {
          .add-trusted-doc__info-input {
            gap: 20px;

            &> :first-child {
              max-width: 220px;
            }
          }
        }

        .add-trusted-doc__form-files {
          gap: 30px;
        }
      }
    }
  }
}