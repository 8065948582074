@import "src/variables";

.back-btn {
  padding: 40px 80px 100px;
}

@media (max-width: $media-max-width-lg) {
  .back-btn {
    padding: 54px 16px 100px;
  }
}

.section-delimiter {
  height: 0;
  margin: -1px 0;
  border: 1px solid #D9D9D9;
}

.trusted-doc-section {

  .trusted-doc-section__text-subsection {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .trusted-doc-section__doc-subsection {
    margin-top: 32px;
  }
}