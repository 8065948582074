@import "src/variables";

.other-docs-section {
  display: flex;
  gap: 117px;
}

@media (max-width: $media-max-width-lg) {
  .other-docs-section {
    display: flex;
    gap: 27px;
  }
}
