@import "src/variables";
@import "src/scenes/subject/certificates/certificates-common-styles.module";

.eapplication-section {
  display: flex;
  align-items: center;
  gap: 40px;
}

.grounds-for-cancellation {
  padding: 40px 20px;
}

.grounds-for-cancellation__title {
  margin-bottom: 40px;
}

.grounds-for-cancellation__upload-title {
  margin-bottom: 20px;
  font: $font-regular-16;
}

.grounds-for-cancellation__upload-container {
  max-width: 140px;
  max-height: 196px;
  margin-bottom: 40px;
}

.grounds-for-cancellation__upload-content {
  max-width: 140px;
  max-height: 196px;
}

.search-container__input {
  max-width: 850px;
  margin: 40px 0px;
}

@media (max-width: $media-max-width-lg) {
  .eapplication-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .grounds-for-cancellation {
    flex-direction: column;
    padding: 40px 16px;

    button {
      width: max-content;
    }
  }

  .grounds-for-cancellation__title {
    margin-bottom: 30px;
    font: $font-regular-16;
  }

  .grounds-for-cancellation__upload-title {
    font: $font-regular-14;
  }


  .grounds-for-cancellation__upload-container {
    margin-bottom: 30px;
  }
}