@import "src/variables";

.certificate-issuing {
  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
    column-gap: 40px;

    &__without-add-primal {
      justify-content: flex-end;
    }
  }

  .download-report-button-container {
    width: max-content;
    margin-top: 10px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .tooltip-hint {
    z-index: 99999;
    max-width: 600px;
    width: max-content;
    line-height: 180%;
  }
}

.actions {
  display: flex;
  gap: 10px;
}

.delete-icon-button {
  div {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .certificate-issuing {
    .buttons-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 20px;

      &__without-add-primal {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: initial;
      }
    }

    .download-report-button-container {
      width: max-content;
      margin-top: 0;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .certificate-issuing__btn {
      width: max-content;
    }

    .tooltip-hint {
      z-index: 99999;
      max-width: 400px;
      width: max-content;
      line-height: 180%;
    }

  }
}