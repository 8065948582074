@import "src/variables";

.modal-container {
  max-width: 730px;
}

.signature-modal {
  display: flex;
  flex-direction: column;

  .signature-modal__title {
    margin-bottom: 35px;
    font: $font-regular-24;
  }

  .signature-modal__processed {
    margin-bottom: 5px;
    font: $font-regular-12;
    color: $color-grey-600;
  }

  .signature-modal__info {
    max-width: 400px;
    font: $font-regular-16;
    color: $color-grey-600;
  }

  .signature-modal__notification {
    margin-top: 30px;
    margin-bottom: 0;
    background-color: $color-red-100;
  }

  .signature-modal__status-container {
    display: flex;
    justify-content: space-between;
    padding-right: 28px;
  }

  .signature-modal__status-diagram-block {
    display: flex;
    align-items: center;
  }

  .signature-modal__diagram-title {
    margin-right: 20px;
    font: $font-regular-12;
  }

  .signature-modal__form {
    margin-top: 20px;

    .signature-modal__form-title {
      display: block;
      margin-bottom: 30px;
      font: $font-regular-12;
      color: $color-grey-600;
    }

    .signature-modal__form-controls {
      display: flex;
      margin-top: 48px;

      .btn {
        padding: 15px 40px;
        height: 50px;

        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .signature-modal {
    .processed {
      font: $font-regular-10;
    }

    .signature-modal__title {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 500;
      font: $font-regular-16;
    }

    .signature-modal__info {
      font: $font-regular-12;
    }

    .signature-modal__status-container {
      flex-direction: column;
      padding-right: 0;
    }

    .signature-modal__status-diagram-block {
      margin-top: 30px;
    }

    .signature-modal__form {
      .signature-modal__form-title {
        font: $font-regular-10;
      }

      .signature-modal__form-controls {
        flex-direction: column;
        width: max-content;
        margin-top: 30px;

        .btn {
          display: flex;
          flex-direction: column;

          &:first-child {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}