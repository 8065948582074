@import "src/variables";

.application-layout {
  padding: 42px 282px;
}

@media (max-width: $media-max-width-lg) {
  .application-layout {
    padding: 30px 16px 100px 16px;
  }
}
