@import "src/variables";

.tooltip-hint {
  z-index: 99999;
}

.header {
  .header__title {
    display: flex;
    align-items: center;
    gap: 20px;

    .header__title-emblems {
      display: flex;
      align-items: center;
      gap: 19px;

      img {
        height: 50px;
      }
    }
  }

  .vertical-divider {
    display: inline-block;

    &:before {
      content: "";
      display: block;
      width: 3px;
      height: 25px;
      background-color: #0c5ab5;
    }

    &:after {
      content: "";
      display: block;
      width: 3px;
      height: 25px;
      background-color: $color-yellow;
    }

    &_gray {
      &:before {
        width: 1px;
        background-color: $color-grey-400;
      }

      &:after {
        width: 1px;
        background-color: $color-grey-400;
      }
    }
  }

  .header__center-part {
    display: flex;
    align-items: center;
  }

  .header__disappear-part {}

  .subjects-select {
    .select-option {

      .select-option__label,
      .select-option__sublabel {
        font-size: 12px;
        line-height: 14px;
        max-width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .select-option__label {
        color: #000;
      }

      .select-option__sublabel {
        margin-top: 2px;
        color: #8b9094;
      }
    }

    :global(.eco-select__control) {
      width: 220px;
      background: #ffffff;
      border: 2px solid #e7eef3;
      border-radius: 27px;
      padding: 8px 20px;
      cursor: pointer;
      outline: none;

      &:hover {
        border-color: #d0d6db;
      }
    }

    :global(.eco-select__control--menu-is-open) {
      border-bottom: 2px solid transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        border-color: #e7eef3;
        border-bottom: 2px solid transparent;
      }
    }

    :global(.eco-select__control--is-focused) {
      box-shadow: none;
    }

    :global(.eco-select__indicator-separator) {
      display: none;
    }

    :global(.eco-select__indicator) {
      display: none;
      position: relative;
      padding-right: 0;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #000;
      }

      svg {
        display: none;
      }
    }

    :global(.eco-select__menu) {
      background: #ffffff;
      border: 2px solid #e7eef3;
      border-top: 0;
      border-radius: 0 0 27px 27px;
      box-shadow: none;
      overflow: hidden;
      margin: 0;
      padding: 0 20px;
    }

    :global(.eco-select__option) {
      cursor: pointer;

      &:hover {
        background-color: #e7eef3;
      }
    }

    :global(.eco-select__option--is-selected) {
      background-color: #fff;
      font-weight: 400;
    }

    :global(.eco-select__option--is-focused) {
      background-color: #fff;
    }

    &__one-subject {
      :global(.eco-select__control) {
        &:hover {
          border: 2px solid #e7eef3;
          cursor: default;
        }
      }
    }
  }

  .header__subpart,
  .header__subpart-md {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 20px;
  }

  .header__create-app-btn {
    font-weight: 700;
    color: $color-black;
    cursor: pointer;
  }

  .header__text {
    color: $color-black;
  }

  .header__right-part {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;

    .header__user-section {
      display: flex;
      gap: 10px;
    }

    .header__controls-container {
      &> :first-child {
        margin-right: 10px;
      }
    }
  }
}

.side-menu {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 40px;
  max-width: 424px;
  max-height: 750px;
  overflow-y: auto;
  box-shadow: 10px 0 28px rgba(0, 0, 0, 0.25);
  background-color: $color-white;

  .side-menu__delimiter {
    display: inline-block;
    border-bottom: 1px solid $color-grey-400;
  }

  .side-menu__services {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .side-menu__services-title {
      font: $font-regular-12;
      color: $color-grey-700;
    }

    .side-menu__service {
      display: flex;
      gap: 20px;
      align-items: center;
      cursor: pointer;

      img {
        width: 35px;
        height: 35px;
      }

      a {
        font: $font-regular-12;
      }
    }
  }

  &_visible {
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
  }
}

.helps-menu {
  display: flex;
  flex-direction: column;
}

.helps-link {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 20px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $color-grey-400;
  }
}

.helps-title {
  font: $font-regular-12;
}

.menu__item {
  display: flex;
  cursor: pointer;
  border-bottom: 1px transparent solid;
  transition: all 0.3s ease;
  padding: 4px 0;
  align-items: center;
  width: max-content;

  .item__text {
    margin-left: 25px;
    font: $font-regular-14;
  }

  .item__img {
    display: flex;
  }

  .item__item {
    width: 18px;

    img {
      width: 100%;
    }
  }

  &:hover {
    border-bottom-color: $color-black;
  }
}

@media (max-width: $media-max-width-xl) {
  .header {
    padding: 15px 16px;
    font: $font-regular-12;

    .header__title {
      gap: 10px;

      .header__title-emblems {
        gap: 10px;

        img {
          height: 27px;
        }
      }
    }

    .vertical-divider {
      &:before {
        height: 13.5px;
      }

      &:after {
        height: 13.5px;
      }
    }

    .header__disappear-part .header__subpart {
      display: none;
    }

    .header__right-part {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.common-header-styles {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $max-page-width;
  padding: 14px 20px;
  background-color: $color-white;
  font: $font-regular-14;
}

@media (max-width: $media-max-width-xl) {
  .common-header-styles {
    padding: 15px 16px;
    font: $font-regular-12;
  }
}

@media (max-width: $media-max-width-md) {
  .header {
    padding: 6px 16px;

    .subjects-select {
      display: none;
      width: max-content;
    }

    .mobile-subject__container {
      .subjects-select {
        display: block;
        margin: 20px 0;
      }

      .header__create-app-btn-container {
        padding: 30px 10px;
        border-bottom: 1px solid $color-grey-400;
      }

      .header__create-app-btn {
        font-size: 14px;
      }
    }

    .mobile-subject__personal-container {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      &> :first-child {
        margin-right: 20px;
      }

      span {
        display: block;
        cursor: pointer;
        font-size: 13px;
      }
    }
  }

  .side-menu {
    max-width: 100%;
    width: 100%;
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    box-shadow: none;
    padding: 20px;

    .side-menu__services {
      .side-menu__services-title {
        font: $font-regular-16;
      }

      .side-menu__service {
        a {
          font-size: 13px;
        }
      }
    }

    .side-menu__mobile-log-in-btn {
      display: inline-block;
      margin: 20px 0;

      &>* {
        font-size: 14px;
      }
    }
  }

  .header__disappear-part .header__subpart-md {
    display: none;
  }
}