@import "src/variables";

.clock {
  min-width: 100px;
  font: $font-regular-20;
  color: $color-black;
  text-align: center;

  time {
    display: flex;
    justify-content: center;
    font: inherit;
    color: $color-black;
  }

  .clock__hours,
  .clock__minutes,
  .clock__seconds {
    width: max-content;
  }

  .clock__completed-text {
    max-width: 160px;
    margin-top: 5px;
    font: $font-regular-10;
    color: $color-grey-500;
  }
}

@media (max-width: $media-max-width-lg) {
  .clock {
    font-size: 12px;
  }
}
