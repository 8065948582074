@import "src/scenes/personal-cabinet-page.module";

.personal-cabinet-page {
  padding: 40px 20px 101px 20px;

  .warning {
    margin-bottom: 40px;
  }

  .personal-cabinet-page__status {
    margin-bottom: 40px;
  }

  .cards-container {
    display: flex;
    margin-bottom: 40px;

    .card__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 13px;
      margin-right: 20px;
      max-width: 200px;
      background-color: $color-grey-300;

      .card__header {
        display: flex;
        align-items: center;

        h4 {
          margin-right: 5px;
          color: $color-grey-600;
          font: $font-regular-10;
        }
      }

      .card__content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 45px;

        img {
          margin-right: 25px;
        }

        h1 {
          font: $font-regular-48;
        }

        .card__red {
          color: $color-red-500;
        }

        .card__green {
          color: $color-green-500;
        }
      }
    }
  }

  .start-processing-applications-button {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (max-width: $media-max-width-lg) {
  .personal-cabinet-page {
    padding: 40px 17px 262px 17px;

    .cards-container {
      flex-wrap: wrap;
      justify-content: space-between;

      .card__wrapper {
        max-width: 165px;
        padding: 20px 7px;
        margin-bottom: 13px;
        margin-right: 0px;

        .card__header {
          justify-content: space-between;

          h4 {
            margin-right: 0px;
          }
        }

        .card__content {
          justify-content: center;
          padding: 50px 30px 0px 30px;

          img {
            margin-right: 20px;
          }
        }
      }
    }
  }
}