@import "src/scenes/subject/certificates/certificates-common-styles.module";

.success-document-creation {
  &__notification {
    margin: 40px 0;
  }

  &__document-for-use-container {
    margin-top: 40px;
  }
}

@media (max-width: $media-max-width-lg) {
  .success-document-creation {
    &__title {
      font: $font-regular-16;
    }

    &__notification {
      margin: 20px 0 30px;
      font: $font-regular-14;
    }
  }
}
