@import "src/variables";

.load-section-doc {
  display: flex;
  align-items: center;
  gap: 60px;

  .load-section-doc__inputs {
    display: flex;
    flex-direction: column;
    gap: 60px;
    max-width: 250px;
  }
}

@media ($media-max-width-lg) {
  .load-section-doc {
    gap: 46px;

    .load-section-doc__inputs {
      gap: 40px;
      max-width: 156px;
    }
  }
}
