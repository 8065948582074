@import "src/variables";

.tree-table-container {
  margin-top: -24px;
  padding: 8px 0 0 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 23px;

  .tree-table__wrapper {
    width: 100%;
  }

  .tree-table {
    display: flex;

    .tree-table__table {
      width: 100%;
      margin-top: 24px;
      border-collapse: collapse;

      thead {
        font: $font-regular-14;
        font-size: 12px;
        color: $color-grey-500;

        tr {
          th {
            padding: 10px 16px;
            font-weight: 400;
          }
        }
      }

      tbody {
        position: relative;
        background-color: $color-grey-300;
        font: $font-regular-14;
        font-size: 12px;

        tr {
          td {
            text-align: center;
            padding: 22px 12px;
            vertical-align: middle;
          }

          .tree-table__doc-cell {
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
          }

          .tree-table__button-cell {
            padding-top: 7px;
            padding-bottom: 7px;
          }
        }

        .tree-table__label {
          position: absolute;
          display: none;
          font-size: 10px;
          text-align: center;
          color: $color-white;

          &_first {
            top: 0;
            left: 18px;
            display: inline-block;
            background-color: $color-green-700;
          }

          &_last {
            bottom: 0;
            left: 0;
            display: inline-block;
            transform: translateY(100%);
            background-color: $color-black;
          }

          td {
            padding: 5px;
          }
        }
      }
    }

    .tree-table__img {
      margin-left: -1px;
      display: inline-block;
      width: 25px;
      height: 90px;

      &_none {
        display: none;
      }
    }

    .tree-table__btn {
      margin: 63px 0 0 10px;
    }
  }

  .tree-table__label_children {
    width: fit-content;
    padding: 5px;
    display: inline-block;
    font: $font-regular-10;
    color: $color-white;
    background-color: $color-black;
  }
}