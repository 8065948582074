@import "src/variables";

.doc-section__buttons-container {
  display: flex;
  gap: 10px;

  .doc-section__preview-btn {
    padding: 10px !important;
    border-width: 1px;

    &:hover {
      border-width: 1px;
    }
  }
}

.doc-section__close-btn {
  background-color: $color-red-100;

  &:hover {
    background: $color-red-200;
    border: 1px solid $color-black;
  }
}

.doc-section__done-btn {
  & button:first-child {
    background-color: $color-green-200;

    &:hover {
      background: $color-green-300;
      border: 1px solid $color-black;
    }
  }
}