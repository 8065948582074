@import "src/variables";

.customs-declaration {
  display: flex;
  flex-direction: column;
  padding: 0 20px 80px;

  .customs-declaration__title {
    margin-top: 47px;
    font: $font-regular-32;
  }

  .customs-declaration__form {
    .customs-declaration__form-title {
      margin-top: 80px;
      font: $font-regular-20;
    }

    .customs-declaration__subform {
      display: flex;
      gap: 55px;
      align-items: center;
      margin-top: 20px;

      .customs-declaration__subform-column {
        display: flex;
        flex-direction: column;
        gap: 55px;

        &_one-element {
          margin-bottom: 80px;
        }
      }
    }

    .customs-declaration__table-form {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .customs-declaration__table-form-reminder {
        margin: 0;
      }

      .customs-declaration__table-form-selects {
        padding-top: 20px;
        display: flex;
        gap: 40px;

        &>* {
          width: 250px;
        }
      }

      .customs-declaration__table-button {
        width: max-content;
      }

      .customs-declaration__table-form-controls {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: $media-max-width-md) {
  .customs-declaration {
    padding: 0 16px 80px;

    .customs-declaration__title {
      margin-top: 40px;
      font: $font-regular-16;
    }

    .customs-declaration__form {
      .customs-declaration__form-title {
        margin-top: 50px;
        font: $font-regular-16;
      }

      .customs-declaration__subform {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: max-content max-content;
        grid-row-gap: 0;
        align-items: start;
        margin-top: 20px;

        .customs-declaration__subform-column {
          grid-column: 1;
          gap: 24px;

          &_one-element {
            margin-top: 24px;
            margin-bottom: 0;
            grid-column: 1;
            grid-row: 2 / none;
          }

          &_loader {
            &>* {
              width: 140px;
              height: 196px;
            }

            grid-column: 2;
            grid-row: 1 / span 2;
          }
        }
      }

      .customs-declaration__table-form {
        gap: 20px;
        margin-top: 28px;

        .customs-declaration__table-form-selects {
          flex-wrap: wrap;

          &>* {
            width: 100%;
          }
        }
      }
    }
  }
}