@import "src/variables";

.define-products-wrapper {
  .define-products__reminder {
    margin-bottom: 60px;
  }

  .define-products {
    .define-products__selects {
      display: flex;
      flex-wrap: wrap;
      gap: 50px;

      &>* {
        width: 250px;
      }
    }

    .define-products__table {
      margin-top: 40px;
    }

    .define-products__table-checkbox-wrapper {
      display: flex;
      justify-content: center;
    }

    .define-products__table-btn {
      width: 46px;
      height: 46px;
      margin: 0 auto;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .form-btns {
    display: flex;
    gap: 20px;
  }
  
  .define-products__btn {
    margin-top: 40px;
  }
}


@media (max-width: $media-max-width-lg) {
  .define-products-wrapper {
    .define-products {
      .define-products__selects {
        display: flex;
        gap: 26px;

        &>* {
          width: 100%;
        }
      }

      .define-products__table {
        margin-top: 40px;
      }
    }
  }
}