@import "src/variables";

.product-types-form {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  &__title {
    font: $font-regular-16;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  &__field-container {
    max-width: 610px;
  }
}

.radio-block {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: -20px;
  }
}