@import "src/variables";

.print-header {
  background-color: $color-white;
  font: $font-regular-12;
  border-bottom: 2px $color-black solid;

  .print-header__center {
    display: none;
    gap: 10px;
    font: $font-regular-10;

    .print-header__center-text {
      color: $color-grey-500;
    }
  }

  .print-header__right {
    .print-header__right-btn {
      display: none;
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .print-header {
    padding: 20px;
    font: $font-regular-10;

    .print-header__disappear-part {
      display: none;
    }

    .print-header__center {
      display: flex;
      align-items: center;
    }

    .print-header__right {
      display: inline-block;

      .print-header__right-btn {
        display: inline-block;
      }
    }
  }
}