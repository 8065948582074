@import "src/variables";

.icon-button {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: transparent;
  font: $font-regular-12;
  cursor: pointer;
  transition: 0.2s;
  &.icon-button--disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  &:hover {
    opacity: 0.6;
  }
}
