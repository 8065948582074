@import "src/variables";

.page-container {
  padding: 77px 80px;
}

.notifications-wrapper {
  max-width: 840px;
}

.notifications-list {
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
}

.title {
  font: $font-regular-32;
  margin-bottom: 40px;
}

.questions-container {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.question-counter {
  font: $font-regular-16;
}

.question-text {
  font: $font-regular-16;
}

.video-container {
  margin-top: 50px;
}

.image-container {
  width: 100%;
  margin-top: 50px;
  & img {
    max-width: 100%;
    width: auto;
    max-height: 400px;
    height: auto;
    object-fit: contain;
  }
}

.service {
  display: none;
  &.active {
    display: block;
  }

  &-item {
    font-size: 28px;
    line-height: 31px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    padding: 39px 0;
    &:first-of-type {
      border-top-width: 2px;
    }
    &:last-of-type {
      border-bottom-width: 2px;
    }
  }
  &-btn {
    width: max-content;
  }
  &-arrow {
    transform: rotate(180deg);
    padding-right: 20px;
  }
  &-items {
    margin: 38px 0 60px;
  }
}

.success-container {
  display: flex;
  flex-direction: column;

  & button {
    width: max-content;
  }
}

@media (max-width: $media-max-width-lg) {
  .page-container {
    padding: 40px 16px;
  }

  .service {
    &-items {
      margin: 39px 0;
    }
    &-item {
      font: $font-regular-16;
      padding: 20px 0;
    }
  }
}

@media (max-width: $media-max-width-sm) {
  .service {
    &-item {
      font: $font-regular-14;
    }
  }
}
