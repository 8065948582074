@import "src/variables";


.children-container {
  display: flex;
  flex-direction: column;
  background-color: $color-grey-300;
  padding: 0 120px 40px 40px;


  .children-container__table {
    width: 100%;
    border-collapse: collapse;
    border: 2px $color-grey-500 solid;

    &:not(:last-of-type) {
      border-bottom: none;
    }

    thead {
      font: $font-regular-14;
      font-size: 12px;
      background-color: $color-white;
      color: $color-grey-500;

      tr {
        th {
          padding: 10px 20px 5px;
          font-weight: 400;
          text-align: left;
        }
      }
    }

    tbody {
      position: relative;
      background-color: $color-white;
      font: $font-regular-14;
      font-size: 12px;

      tr {
        td {
          padding: 5px 20px 15px;
          vertical-align: middle;
        }

        .children-container__doc-cell {
          position: relative;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        .children-container__button-cell {
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }

    }
  }
}