@import "variables";

.results-container {
  margin-top: 90px;
  .results-title {
    margin-bottom: 30px;
    font: $font-regular-24;
  }

  .results-subtitle {
    color: $color-grey-600;
    font: $font-regular-16;
  }
}
