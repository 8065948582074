@import "src/variables";

.tooltip-hint {
  z-index: 99999;
  max-width: 300px;
  line-height: 180%;
}

.basic-docs-section-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 40px;
  margin-top: 40px;

  img {
    width: 24px;
    height: 24px;
  }

  &_less-space {
    margin-top: 10px;
  }
}

.basic-docs-section-totals {
  width: fit-content;
  display: flex;
  gap: 20px;
  margin-left: auto;

  .basic-docs-section-totals__desc {
    color: $color-black;
  }

  .basic-docs-section-totals__title {
    color: $color-grey-500;
  }

  .basic-docs-section-totals__value {
    color: $color-black;
  }
}

@media (max-width: $media-max-width-lg) {
  .basic-docs-section-footer {
    flex-direction: column-reverse;
    row-gap: 40px;
    align-items: flex-start;
  }
}
