@import "src/variables";

.add-document-form {
  margin-top: 80px;

  &__wrapper {
    display: flex;

    & > :first-child {
      margin-right: 55px;
    }
  }

  &__fields-container {
    max-width: 556px;
    width: 100%;
  }

  &__fields-row {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 40px;

    & > :first-child {
      margin-right: 55px;
    }
  }

  &__small-wrapper {
    width: 50%;
  }

  &__large-wrapper {
    width: 100%;
  }
}

@media (max-width: $media-max-width-lg) {
  .add-document-form {
    &:first-of-type {
      margin-top: 30px;
    }

    &__wrapper {
      position: relative;

      & > :first-child {
        margin-right: 26px;
      }
    }

    &__title {
      margin-bottom: 30px;
      font: $font-regular-16;
    }

    &__fields-row {
      flex-direction: column;
      width: 100%;
      margin-bottom: 100px;
      margin-top: 0px;

      & > :first-child {
        margin-right: 0px;
        margin-bottom: 40px;
      }
    }

    &__fields-container {
      & > :last-child {
        position: absolute;
        bottom: -60px;
      }
    }

    &__form-file-loader {
      margin-bottom: 60px;
    }

    &__small-wrapper {
      width: 100%;
    }

    .upload-wrapper {
      min-width: 140px;
      height: 196px;
    }
  }
}
