@import "src/variables";

.modal-container {
  max-width: 730px;
}

.pdf-modal {
  display: flex;
  flex-direction: column;

  .pdf-modal__title {
    margin-bottom: 35px;
    font: $font-regular-24;
  }

  .pdf-modal__controls-container {
    display: flex;
    margin-top: 30px;

    &>*:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.modal-download {
  height: max-content;
  width: fit-content;
  margin-top: 40px;
  padding: 14px 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  background: #000;
  border: none;
  border-radius: 30px;
  font: normal 700 14px e_Ukraine_Bold,
    sans-serif;
  text-align: center;
  cursor: pointer;

  &:visited {
    color: #fff;
  }

  &:hover {
    background: linear-gradient(68.94deg, #c3aab2 -4.77%, #99eecc 46.72%, #80c0c8 90.23%, #4b8bfa 134.46%);
    color: #000;

    img {
      filter: unset
    }
  }

  img {
    filter: invert(1);
  }
}

@media (max-width: $media-max-width-lg) {
  .pdf-modal {
    .processed {
      font: $font-regular-10;
    }

    .pdf-modal__title {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 500;
      font: $font-regular-16;
    }

    .pdf-modal__controls-container {
      flex-direction: column;

      &>*:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
      }

      &>* {
        width: max-content;
      }
    }
  }
}