@import "src/variables";

.radio-section {
  &__title {
    margin-bottom: 40px;
    font: $font-regular-16;
  }
}

.radio-section-container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.radio-group-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.controls-container {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.product-types-form {
  &__title {
    font: $font-regular-16;
  }

  &__field-container {
    max-width: 610px;
  }
}

.file-uploader__container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

@media (max-width: $media-max-width-md) {
  .controls-container {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
}
