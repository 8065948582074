@import "src/variables";

.create-zsn {
  padding: 20px;
  border: 2px solid $color-grey-500;

  &_submitted {
    background: $color-grey-300;
  }

  .create-zsn__title {
    font: $font-regular-24;
  }

  .create-zsn__form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;

    .create-document-page__table-form-selects {
      display: flex;
      gap: 40px;

      &>* {
        width: 250px;
      }
    }

    .create-zsn__form-first-part {
      display: grid;
      grid-template-columns: minmax(min-content, 1fr) max-content;
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      align-items: center;
      width: fit-content;

      .create-zsn__form-text-fields {
        .create-zsn__form-row {
          display: flex;
          flex-wrap: wrap;
          gap: 40px;

          .create-zsn__form-text-item {
            font: $font-regular-12;
            color: $color-grey-600;

            &>*:last-child {
              margin-top: 5px;
            }
          }

          &_first {
            grid-column: 1;
            grid-row: 1;
            margin-bottom: 66px;
          }

          &_second {
            grid-column: 1;
            grid-row: 1;

          }

          &>* {
            width: 250px;
          }
        }
      }

      .create-zsn__form-loader {
        grid-column: 2;
        grid-row: 1;
      }
    }

    .create-zsn__form-buttons {
      display: flex;
      gap: 20px;
    }

    .create-zsn__form-button {
      width: min-content;
    }
  }
}

@media (max-width: $media-max-width-md) {
  .create-zsn {
    padding: 20px 10px;

    .create-zsn__title {
      font: $font-regular-16;
    }

    .create-zsn__form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;

      .create-document-page__table-form-selects {
        flex-direction: column;
        gap: 26px;

        &>* {
          width: 100%;
        }
      }

      .create-zsn__form-first-part {

        .create-zsn__form-text-fields {
          .create-zsn__form-row {

            &_first {
              margin-bottom: 44px;
            }

            &>* {
              width: 100%;
            }
          }
        }

        .create-zsn__form-loader {
          align-self: start;
          grid-column: 2;
          grid-row: 1;
        }
      }
    }
  }
}