@import "src/variables";

.breadcrumbs-wrapper {
  padding: 5px 0 0 0;
  font: $font-regular-10;
  font-size: 11px;

  .breadcrumbs {
    display: flex;
    list-style: none;

    .breadcrumbs__item {
      .breadcrumbs-link-container {
        display: flex;
        align-items: center;
      }

      .breadcrumbs__item-link {
        position: relative;
        display: block;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-grey-500;
        &::after {
          content: "->";
          position: absolute;
          right: -15px;
          width: 15px;
        }
        &:active {
          color: $color-grey-500;
        }
      }
      &_no-link {
        pointer-events: none;
        color: $color-grey-500;
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .breadcrumbs-wrapper {
    display: none;
  }
}
