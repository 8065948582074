@import "src/variables";
@import "src/scenes/subject/certificates/certificates-common-styles.module";

.concern-section {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .concern-section__title {
    padding-bottom: 0;
  }

  .concern-section__concern {
    display: flex;
    flex-direction: column;
    gap: 40px;

    h3 {
      font-weight: 400;
      font: $font-regular-20;
    }
  }
}

.annulment-section {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__annulment {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__attached-files-container {
    display: flex;
    gap: 40px;
    align-items: center;
  }

  .annulment-section__title {
    padding-bottom: 0;
    font-size: 24px;
  }
}

.declined-page-tabs {
  menu {
    align-items: baseline;
  }
}

.official-controls__wrapper {
  padding: 60px 80px;
  background: $color-grey-300;
}

.controls-wrapper {
  display: flex;
  padding: 40px 20px;

  button:first-child {
    margin-right: 30px;
  }
}

@media (max-width: $media-max-width-lg) {
  .concern-section {
    gap: 30px;

    .concern-section__concern {
      gap: 20px;

      h3 {
        font: $font-regular-14;
      }
    }
  }

  .annulment-section {
    gap: 30px;

    &__annulment {
      gap: 20px;
    }

    &__attached-files-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .annulment-section__title {
      font-size: 14px;
    }
  }

  .official-controls__wrapper {
    padding: 40px 16px;
    background: $color-grey-300;
  }

  .controls-wrapper {
    flex-direction: column;
    padding: 40px 16px;

    button {
      width: max-content;
    }

    button:first-child {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}
