@import "src/variables";

.grounds-for-cancellation__title {
  font: $font-regular-24;
}

.grounds-for-cancellation__text {
  max-width: 850px;
  font: $font-regular-16;
  line-height: 130%;
  color: $color-grey-700;
}

.grounds-for-cancellation__documents {
  margin-top: 45px;

  .grounds-for-cancellation__documents-title {
    font: $font-regular-16;
  }

  .grounds-for-cancellation__documents-files {
    margin-top: 20px;
  }
}

@media (max-width: $media-max-width-lg) {
  .grounds-for-cancellation__small-title {
    font: $font-regular-16;
    font-weight: 500;
  }

  .grounds-for-cancellation__text {
    font: $font-regular-12;
  }
}