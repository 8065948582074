@import "src/variables";

.certificate-info {
  padding: 40px 20px;

  &>h4 {
    margin-bottom: 44px;
  }
}

.certificate-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.certificate-info-single-wrapper {
  display: flex;
  flex-direction: column;
  padding-right: 10px;

  &:last-child {
    padding-right: 0px;
  }
}

.certificate-info-single-small {
  width: 25%;
}

.certificate-info-single-medium {
  width: 50%;
}

.certificate-info-single-large {
  width: 100%;
}

.certificate-info-label {
  margin-bottom: 5px;
  font: $font-regular-12;
  color: $color-grey-600;
}

.certificate-info-text {
  max-width: 400px;
  font: $font-regular-16;
  color: $color-grey-600;
}

@media (max-width: $media-max-width-lg) {
  .certificate-info-title {
    font: $font-regular-16;
  }

  .certificate-info {
    padding: 0 16px;
    margin: 40px 0px 30px;

    &>h4 {
      margin-bottom: 20px;
    }
  }

  .certificate-info-container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
  }

  .certificate-info-single-wrapper {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .certificate-info-single-small {
    width: 100%;
  }

  .certificate-info-single-medium {
    width: 100%;
  }

  .certificate-info-label {
    font: $font-regular-10;
  }

  .certificate-info-text {
    font: $font-regular-12;
  }
}