@import "src/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 40px 80px 90px 80px;
}

.notification {
  margin: 0;
}

.settings-form-container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 550px;
}

.form-block {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.middle-block {
  max-width: 368px;
}

.radio-group-container {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.passport-container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.dual-passport-container {
  display: flex;
  align-items: center;
  column-gap: 20px;
  max-width: 350px;
}

.controls-container {
  display: flex;
  align-items: center;
  column-gap: 40px;
  margin-top: 20px;
}

.small-field {
  max-width: 80px;
}

.middle-filed {
  max-width: 250px;
}

.building-checkbox {
  margin-top: -20px;
  gap: 10px;
  & span {
    font: $font-regular-14 !important;
  }
}

.not-private-address-container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

@media (max-width: $media-max-width-lg) {
  .wrapper {
    padding: 40px 16px;
    row-gap: 40px;
  }
  .settings-title {
    font: $font-regular-20;
  }

  .form-block {
    &__title {
      font: $font-regular-16;
    }
  }

  .controls-container {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
}
