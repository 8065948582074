@import "src/variables";

.modal-container {
  max-width: 730px;
}

.picture {
  max-width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}