@import "src/variables";

.specify-usage {
  display: flex;
  flex-direction: column;
  padding: 0 20px 80px;

  .specify-usage__title {
    margin-top: 47px;
    font: $font-regular-32;
  }

  .specify-usage__doc {
    margin-top: 20px;
  }

  .specify-usage__table-controls {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 40px;

    .specify-usage__table-controls-button {
      img {
        width: 24px;
        height: 24px;
      }
    }

    .specify-usage__table-controls-text {
      color: $color-grey-600;

      & > *:first-child {
        font: $font-regular-12;
      }

      & > *:last-child {
        margin-top: 5px;
        font: $font-regular-16;
      }
    }
  }

  .certificates-navigations-controls-container {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .navigation-button {
      height: 34px;
      width: 34px;
      margin-top: 40px;
      margin-bottom: 20px;

      &__first {
        transform: rotate(90deg);
      }
      &__next {
        transform: rotate(180deg);
      }
      &__last {
        transform: rotate(-90deg);
      }
    }
  }

  .certificates-numbering {
    margin-bottom: 40px;
    font: $font-regular-12;
  }

  .dp-for-certificate {
    font: $font-regular-16;
  }

  .specify-usage__table-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;

    .specify-usage__table-form-title {
      font: $font-regular-24;
    }

    & > *:first-child {
      padding-top: 0;
    }
  }
}

@media (max-width: $media-max-width-md) {
  .specify-usage {
    padding: 0 16px 80px;

    .specify-usage__title {
      margin-top: 47px;
      font: $font-regular-32;
    }

    .specify-usage__table-controls {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;

      & > *:first-child {
        margin-bottom: 30px;
      }
    }

    .specify-usage__table-form {
      margin-top: 20px;
      gap: 20px;

      .specify-usage__table-form-title {
        font: $font-regular-16;
      }

      & > *:first-child {
        padding-top: 0;
      }
    }
  }
}
