@import "src/variables";

.footer {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  padding: 80px 80px 52px 80px;
  background-color: $color-black;
  color: $color-white;

  .footer__navigation {
    display: flex;
    justify-content: space-between;

    .footer__navigation-group {
      & > :not(:first-child) {
        margin-top: 20px;
      }

      .footer__navigation-link {
        display: block;
        border: none;
        font: $font-regular-16;
        line-height: 130%;
        letter-spacing: -0.02em;
        text-decoration: none;
        color: $color-white;
        background: 0;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          text-decoration: none;
          outline: none;
        }
      }

      .footer__text {
        @extend .footer__navigation-link;
        cursor: initial;
      }

      .footer__link {
        display: inline-block;
      }
    }
  }

  .footer-hr {
    display: none;
    margin: 24px 0;
    height: 2px;
    background-color: $color-grey-600;
    width: auto;
    border-top: none;
  }

  .footer__copyright {
    display: flex;
    align-items: center;
    margin-top: 47px;

    & > :first-child {
      margin-right: 10px;
    }

    .footer__copyright-text {
      color: $color-grey-500;
      margin-left: 27px;
    }
  }

  .footer__about {
    display: flex;
    width: 50%;
    margin-top: 40px;
    font-size: 13px;
  }
}

@media (max-width: $media-max-width-lg) {
  .footer {
    padding: 24px 16px;

    .footer__navigation {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: max-content max-content;
      grid-row-gap: 20px;
      grid-column-gap: 24px;
      align-items: start;

      & > :last-child {
        grid-column: 1 / span 2;
      }

      .footer__navigation-group {
        & > :not(:first-child) {
          margin-top: 5px;
        }

        .footer__navigation-link {
          font-size: 13px;
        }
      }

      .footer__navigation-group_follow {
        grid-column: 1 / span 2;
        display: grid;
        grid-template-columns: 90px max-content;
        grid-column-gap: 24px;

        & > :last-child {
          margin-top: 0;
        }
      }
    }

    .footer-hr {
      display: block;
      margin: 24px 0;
    }

    .footer__copyright {
      margin: 0;
    }

    .footer__about {
      width: 100%;
      font: $font-regular-10;
    }
  }
}
