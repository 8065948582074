@import "src/scenes/personal-cabinet-page.module";

.personal-cabinet-page {
  .personal-cabinet-page__title {
    margin-bottom: 40px;
    font: $font-regular-24;
  }

  .container-border {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;
    padding: 20px;
    border: 2px solid black;
    border-image: linear-gradient(to right, #6372ff, #925182, #b19956, #d97d34) 1;

    .information-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 15px;

      .information-block__order-number {
        margin-bottom: 20px;
        font: $font-regular-16;
      }

      .main-information-wrapper {
        display: flex;

        .main-information {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 5px;

          .main-information__row {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .main-information__data-wrapper {
              display: flex;
              flex-direction: column;
              width: fit-content;
              padding-bottom: 20px;
              margin-right: 15px;

              .main-information__title {
                margin-bottom: 15px;
                color: $color-grey-500;
                font: $font-regular-10;
              }

              .main-information__value {
                max-width: 200px;
                margin-top: auto;
                margin-bottom: auto;
                font: $font-regular-12;
              }

              .main-information__remark {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 46px;
                height: 46px;
                border: 1px solid black;
                border-radius: 50%;
                margin-top: auto;
                margin-bottom: auto;
                background-color: $color-green-200;
                font: $font-regular-20;
              }

              .rate-container {
                margin-top: auto;
                margin-bottom: auto;

                .rate-container__rate {
                  margin-bottom: 5px;
                  font: $font-regular-12;
                }

                .rate-container__costs {
                  font: $font-regular-12;
                }
              }

              .main-information__time {
                margin-top: auto;
                margin-bottom: auto;
                font: $font-regular-24;
              }
            }
          }
        }
      }
    }
  }
}

.queue {
  margin-top: 40px;
}

@media (max-width: $media-max-width-lg) {
  .personal-cabinet-page {
    .personal-cabinet-page__title {
      margin-bottom: 20px;
      font-weight: 500;
      font: $font-regular-16;
    }

    .container-border {
      position: relative;
      flex-direction: column;
      padding: 30px 20px;

      .information-block {
        width: 100%;

        .main-information-wrapper {
          flex-direction: column;

          .main-information {
            flex-direction: column;

            .main-information__row {
              flex-direction: column;
              width: 100%;

              .main-information__data-wrapper {
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }


}