@import "src/variables";

.bar-wrapper {
  display: flex;
  justify-content: center;

  .circle-progress-bar {
    position: relative;
    display: block;
    height: 60px;
    width: 60px;
    border-radius: 100%;

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: attr(data-pct) "%";
      border-radius: 100%;
      font: $font-regular-12;
      color: $color-black;
    }

    .circle-progress-bar__base {
      stroke: $color-grey-400;
      stroke-width: 5px;
      stroke-linecap: round;
    }

    .circle-progress-bar__bar {
      stroke: $color-green-500;
      stroke-width: 5px;
      stroke-linecap: round;
      transform-origin: center center;
      transform: rotateY(180deg) rotateZ(-90deg);
    }
  }

  .circle-progress-bar-big-only {
    position: relative;
    display: block;
    height: 60px;
    width: 60px;
    border-radius: 100%;

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: attr(data-pct) "%";
      border-radius: 100%;
      font: $font-regular-12;
      color: $color-black;
    }

    .circle-progress-bar__base-big-only {
      stroke: $color-grey-400;
      stroke-width: 5px;
      stroke-linecap: round;
    }

    .circle-progress-bar__bar-big-only {
      stroke: $color-green-500;
      stroke-width: 5px;
      stroke-linecap: round;
      transform-origin: center center;
      transform: rotateY(180deg) rotateZ(-90deg);
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .bar-wrapper {
    .circle-progress-bar {
      height: 34px;
      width: 34px;

      &:after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: attr(data-pct) "%";
        border-radius: 100%;
        font: $font-regular-10;
      }

      .circle-progress-bar__base {
        stroke: $color-grey-400;
        stroke-width: 3px;
        stroke-linecap: round;
      }

      .circle-progress-bar__bar {
        stroke: $color-green-500;
        stroke-width: 3px;
        stroke-linecap: round;
        transform-origin: center center;
        transform: rotateY(180deg) rotateZ(-90deg);
      }
    }
  }
}
