@import "src/variables";

.create-rail-waybill {
  display: flex;
  flex-direction: column;
  padding: 0 20px 80px;

  .create-rail-waybill__title {
    margin-top: 47px;
    font: $font-regular-32
  }

  .create-rail-waybill__table-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 84px;

    .create-rail-waybill__table-form-title {
      font: $font-regular-20;
    }

    .create-rail-waybill__table-form-reminder {
      margin: 0;
    }

    .create-rail-waybill__specifications {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .create-rail-waybill__table-button {
      width: max-content;
    }

    .create-rail-waybill__table-form-controls {
      margin-top: 0;
    }
  }
}

@media (max-width: $media-max-width-md) {
  .create-rail-waybill {
    padding: 0 16px 80px;

    .create-rail-waybill__title {
      margin-top: 40px;
      font: $font-regular-16
    }

    .create-rail-waybill__table-form {
      gap: 20px;
      margin-top: 28px;

      .create-rail-waybill__table-form-title {
        font: $font-regular-16;
      }
    }
  }
}
