.disabled-container {
  position: relative;
  width: 100%;

  &.disabled-container--active {
    opacity: 0.5;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}