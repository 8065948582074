@import "src/variables";
@import "src/scenes/subject/certificates/certificates-common-styles.module";

.eapplication-section {
  display: flex;
  align-items: center;
  gap: 40px;
}

.grounds-for-cancellation {
  padding: 40px 20px;
}

.grounds-for-cancellation__title {
  margin-bottom: 40px;
}

.grounds-for-cancellation__upload-title {
  margin-bottom: 20px;
  font: $font-regular-16;
}

.grounds-for-cancellation__upload-container {
  max-width: 140px;
  max-height: 196px;
  margin-bottom: 40px;
}

.grounds-for-cancellation__upload-content {
  max-width: 140px;
  max-height: 196px;
}

.annul-request__main-info {
  padding: 40px 20px;

  &>h4 {
    margin-bottom: 44px;
  }
}

.annul-request__main-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.annul-request__main-info-single-wrapper {
  display: flex;
  flex-direction: column;
  padding-right: 10px;

  &:last-child {
    padding-right: 0px;
  }
}

.annul-request__main-info-single-small {
  width: 25%;
}

.annul-request__main-info-single-medium {
  width: 50%;
}

.annul-request__main-info-single-large {
  width: 100%;
}

.annul-request__main-info-label {
  margin-bottom: 5px;
  font: $font-regular-12;
  color: $color-grey-600;
}

.search-container__input {
  max-width: 850px;
  margin: 40px 0px;
}

.annul-request__main-info-text {
  max-width: 400px;
  font: $font-regular-16;
  color: $color-grey-600;
}

@media (max-width: $media-max-width-lg) {
  .eapplication-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .grounds-for-cancellation {
    flex-direction: column;
    padding: 40px 16px;

    button {
      width: max-content;
    }
  }

  .grounds-for-cancellation__title {
    margin-bottom: 30px;
    font: $font-regular-16;
  }

  .grounds-for-cancellation__upload-title {
    font: $font-regular-14;
  }

  .annul-request__main-info-title {
    font: $font-regular-16;
  }

  .annul-request__main-info {
    padding: 0 16px;
    margin: 40px 0px 30px;

    &>h4 {
      margin-bottom: 20px;
    }
  }

  .annul-request__main-info-container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
  }

  .annul-request__main-info-single-wrapper {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .annul-request__main-info-single-small {
    width: 100%;
  }

  .annul-request__main-info-single-medium {
    width: 100%;
  }

  .grounds-for-cancellation__upload-container {
    margin-bottom: 30px;
  }

  .annul-request__main-info-label {
    font: $font-regular-10;
  }

  .annul-request__main-info-text {
    font: $font-regular-12;
  }
}