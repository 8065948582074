@import "src/scenes/subject/certificates/certificates-common-styles.module";

.create-primary-document {
  &__form-container {
    max-width: 1150px;
    width: 100%;
    margin: 60px 0 40px;
  }

  &__field-row {
    display: flex;
    // align-items: center;
    margin-bottom: 40px;

    &>*:not(:last-child) {
      margin-right: 50px;
    }
  }

  .item-checkbox {
    margin: 20px 0;
    gap: 10px;

    div span {
      font-size: 14px !important;
    }
  }

  &__small-field-item {
    width: 250px;
  }

  &__medium-field-item {
    width: 550px;
  }

  &__location-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  &__location-label {
    margin-bottom: 5px;
    font: $font-regular-12;
    color: $color-grey-600;
  }

  &__location-text {
    font: $font-regular-16;
    color: $color-grey-600;
  }

  &__form-controls-wrapper {
    display: flex;

    &> :first-child {
      margin-right: 20px;
    }
  }

  &__notification {
    margin: 20px 0 0;
  }

  &__no-results-search-notification {
    background: $color-red-200;
  }

  &__container {
    margin-top: 40px;
    display: flex;
  }

  &__main-controls-container {
    display: flex;
    margin-top: 40px;

    &> :not(:last-child) {
      margin-right: 40px;
    }
  }

  &__document-for-use-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px
  }

  &__btns {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
}

@media (max-width: $media-max-width-lg) {
  .create-primary-document {
    &__title {
      font: $font-regular-16;
    }

    &__form-container {
      max-width: 100%;
      margin: 40px 0;
    }

    &__field-row {
      flex-direction: column;
      align-items: flex-start;

      &>*:not(:last-child) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    &__small-field-item {
      width: 100%;
    }

    &__medium-field-item {
      width: 100%;
    }

    &__location-label {
      font: $font-regular-10;
    }

    &__location-text {
      font: $font-regular-12;
    }

    &__search-results-title {
      font: $font-regular-16;
    }

    &__notification {
      margin: 30px 0;
      font: $font-regular-14;
    }

    &__main-controls-container {
      flex-direction: column;

      &> :not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
      }

      &>* {
        width: max-content;
      }
    }
  }
}