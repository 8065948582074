@import "src/variables";

.get-certificate {
  color: $color-black;
  background-color: $color-white;

  .get-certificate__header {
    padding: 44px 80px 49px 80px;
    background-color: $color-grey-300;
    text-align: center;

    .get-certificate__header-controls {
      display: flex;
      // justify-content: space-between;
      justify-content: flex-end;
      align-items: flex-start;

      .get-certificate__header-controls_right {
        display: flex;
        flex-direction: column;
        gap: 18px;
      }
    }

    .get-certificate__title {
      font: $font-regular-36;
      font-size: 54px;
      margin-top: 40px;
    }

    .get-certificate__order-btn {
      display: inline-block;
      margin-top: 70px;
    }

    .get-certificate__notice {
      display: block;
      font: $font-regular-12;
      color: $color-grey-500;
      margin-top: 30px;
    }
  }

  .service {
    display: none;

    &.active {
      display: block;
    }

    &-item {
      font-size: 28px;
      line-height: 31px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-top: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      padding: 39px 0;

      &:first-of-type {
        border-top-width: 2px;
      }

      &:last-of-type {
        border-bottom-width: 2px;
      }
    }

    &-arrow {
      transform: rotate(180deg);
      padding-right: 20px;
    }

    &-items {
      margin: 38px 0 60px;
    }
  }

  .get-certificate__info {
    padding: 100px 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .get-certificate__info-box {
      padding: 40px 60px;
      font: $font-regular-16;
      font-size: 18px;
      border: 2px solid $color-green_300;

      &:first-child {
        border-right-width: 1px;
      }

      &:last-child {
        border-left-width: 1px;
      }

      .get-certificate__info-title {
        color: $color-grey-500;
      }

      .get-certificate__info-text {
        padding-top: 30px;
        line-height: 130%;

        & li {
          list-style-type: disc;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      .get-certificate__info-cost {
        padding-top: 40px;
      }
    }
  }

  .get-certificate__questions {
    padding: 0 80px 90px 80px;
  }
}

@media (max-width: $media-max-width-lg) {
  .get-certificate {
    .get-certificate__header {
      padding: 20px 14px;

      .get-certificate__header-controls {
        .get-certificate__header-controls_right {
          gap: 22px;
        }
      }

      .get-certificate__title {
        font: $font-regular-20;
        margin-top: 40px;
      }

      .get-certificate__order-btn {
        margin-top: 30px;
      }
    }

    .service {
      &-items {
        margin: 39px 0;
      }

      &-item {
        font: $font-regular-16;
        padding: 20px 0;
      }
    }

    .get-certificate__info {
      padding: 40px 16px;
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, max-content);

      .get-certificate__info-box {
        padding: 20px 10px 20px 30px;
        font: $font-regular-14;

        &:first-child {
          border-right-width: 2px;
          border-bottom-width: 1px;
        }

        &:last-child {
          border-left-width: 2px;
          border-top-width: 1px;
        }

        .get-certificate__info-title {
          color: $color-grey-500;
        }

        .get-certificate__info-text {
          padding-top: 20px;
          line-height: 130%;
        }

        .get-certificate__info-cost {
          padding-top: 20px;
        }
      }
    }

    .get-certificate__questions {
      padding: 0 16px 173px 16px;
    }
  }
}

@media (max-width: $media-max-width-sm) {
  .get-certificate {
    .service {
      &-item {
        font: $font-regular-14;
      }
    }
  }
}