@import "src/variables";

.modal-container {
  max-width: 720px;
}

.error-modal {
  display: flex;
  flex-direction: column;

  .error-modal__title {
    margin-bottom: 50px;
    font: $font-regular-24;
  }

  .error-modal__notification {
    margin-top: 30px;
    margin-bottom: 0;
    background-color: $color-red-100;
  }

  .error-modal__progress {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    gap: 20px;

    &>p {
      font-size: 12px;
    }
  }

  .close-btn {
    margin: 50px auto 0 0;
  }
}

@media (max-width: $media-max-width-lg) {
  .error-modal {
    .error-modal__title {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 500;
      font: $font-regular-16;
    }

    .error-modal__status-progress {
      margin-top: 30px;
    }
  }
}