@import "src/variables";

.production-section {
  .production-section__info {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
  }

  .production-section__table {
    margin-top: 40px;

    &>*>* {
      overflow-y: auto;
      max-height: calc(100vh - $max-header-height - 240px);
    }

    thead {
      background-color: $color-grey-300;
      position: sticky;
      top: 0;
      z-index: $table-header-z-index;
    }
  }

  .production-section__total-block {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  .production-section__total-title {
    margin-right: 20px;
    font: $font-regular-16;
  }

  .production-section__total-flex-container {
    display: flex;
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  }

  .production-section__total-label {
    margin-right: 20px;
    font: $font-regular-16;
    color: $color-grey-500;
  }

  .production-section__total-text {
    font: $font-regular-16;
  }
}

@media (max-width: $media-max-width-lg) {
  .production-section {
    .production-section__total-block {
      flex-direction: column;
      margin-top: 20px;
    }

    .production-section__total-title {
      margin-right: 0;
      margin-bottom: 15px;
      font: $font-regular-14;
    }

    .production-section__total-flex-container {
      display: flex;
      margin-right: 0;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .production-section__total-label {
      font: $font-regular-12;
    }

    .production-section__total-text {
      font: $font-regular-12;
    }
  }
}