@import "variables";
@import "src/scenes/personal-cabinet-page.module";

.personal-cabinet-page {
  padding-top: 60px;

  .personal-cabinet-page__title {
    margin-bottom: 40px;
    font: $font-regular-24;
  }

  .personal-cabinet-page__submenu {
    margin-bottom: 60px;
  }
}

@media (max-width: 992px) {
  .personal-cabinet-page {
    .personal-cabinet-page__title {
      margin-bottom: 20px;
      margin-top: 0;
      font-weight: 500;
      font: $font-regular-16;
    }

    .container-border {
      padding: 20px;

      .container-border__bordered-header {
        .statement {
          .statement-content {
            .statement-content__count {
              margin-left: 10px;
              line-height: 24px;
              font: $font-regular-24;
            }
          }
        }

        .statement__title {
          margin-bottom: 15px;
          color: $color-grey-500;
          font: $font-regular-12;
        }

        .dates {
          .dates__date {
            font: $font-regular-12;
          }
        }
      }

      .container-border__annul-button {
        padding: 12px 40px;
      }
    }
  }

  .report-container {
    &__header {
      margin-top: 20px;
      margin-bottom: 30px;
      font: $font-regular-16;

      &:first-child {
        margin-top: 0px;
      }
    }

    &__unnulled-header {
      margin-top: 30px;
      margin-bottom: 30px;
      font: $font-regular-16;
    }

    &__notification {
      font: $font-regular-14;
    }
  }
}