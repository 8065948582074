@import "src/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  max-width: $max-page-width;
  height: $max-header-height;
  background-color: $color-white;
}