@import "variables";

.results-container {
  margin-top: 90px;

  .results-title {
    margin-bottom: 30px;
    font: $font-regular-24;
  }

  .results-subtitle {
    color: $color-grey-600;
    font: $font-regular-16;
  }
}

.actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  & > * {
    min-width: 48px;
  }

  & > :last-child {
    margin-right: 0px;
  }

  .actions-container__annul-button {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 12px;
    & > div {
      margin-right: 5px;
    }
  }

  .actions-container__number-count {
    font: $font-regular-20;
  }
}
