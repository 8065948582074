@import "variables";
@import "src/scenes/personal-cabinet-page.module";

.personal-cabinet-page {
  padding-top: 60px;

  .personal-cabinet-page__title {
    margin-bottom: 40px;
    font: $font-regular-24;
  }

  .personal-cabinet-page__submenu {
    margin-bottom: 60px;
  }
}

.container-border {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 2px solid black;
  margin-bottom: 60px;
  padding: 20px 20px 40px;
  border-image: linear-gradient(to right, #6372ff, #925182, #b19956, #d97d34) 1;


}

.container-border__bordered-header {
  display: flex;
  margin-bottom: 35px;

  .statement {
    margin-right: 90px;

    .statement-content {
      display: flex;
      align-items: center;

      img {
        max-width: 25px;
      }

      .statement-content__count {
        margin-left: 25px;
        line-height: 48px;
        font: $font-regular-48;
      }
    }
  }

  .statement__title {
    margin-bottom: 30px;
    font: $font-regular-12;
    color: $color-grey-500;
  }

  .dates {
    display: flex;
    flex-direction: column;

    .dates__date {
      font: $font-regular-24;
    }
  }
}

.container-border__annul-button {
  width: fit-content;
  margin-top: 20px;
  padding: 20px 60px;
}

.report-container {
  &__header {
    margin-top: 20px;
    margin-bottom: 40px;
    font: $font-regular-24;
  }

  &__unnulled-header {
    margin-top: 80px;
    margin-bottom: 40px;
    font: $font-regular-24;
  }

  &__notification {
    font: $font-regular-16;
  }
}

@media (max-width: 992px) {
  .personal-cabinet-page {
    .personal-cabinet-page__title {
      margin-bottom: 20px;
      margin-top: 0;
      font-weight: 500;
      font: $font-regular-16;
    }

    .container-border {
      padding: 20px;

      .container-border__bordered-header {
        .statement {
          .statement-content {
            .statement-content__count {
              margin-left: 10px;
              line-height: 24px;
              font: $font-regular-24;
            }
          }
        }

        .statement__title {
          margin-bottom: 15px;
          color: $color-grey-500;
          font: $font-regular-12;
        }

        .dates {
          .dates__date {
            font: $font-regular-12;
          }
        }
      }

      .container-border__annul-button {
        padding: 12px 40px;
      }
    }
  }

  .report-container {
    &__header {
      margin-top: 20px;
      margin-bottom: 30px;
      font: $font-regular-16;

      &:first-child {
        margin-top: 0px;
      }
    }

    &__unnulled-header {
      margin-top: 30px;
      margin-bottom: 30px;
      font: $font-regular-16;
    }

    &__notification {
      font: $font-regular-14;
    }
  }
}