@import "src/variables";

.modal-container {
  max-width: 440px;
}

.preloader-modal {
  display: flex;
  flex-direction: column;

  .preloader-modal__title {
    text-align: center;
    margin-top: 35px;
    font: $font-regular-24;
  }
}
