@import "src/scenes/personal-cabinet-page.module";

.search-page {
  font: $font-regular-16;

  .search-page__title {
    font: $font-regular-24;
  }

  .search-page__content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    & > *:not(:first-child) {
      margin-top: 50px;
    }

    .search-page__content-row {
      display: grid;
      grid-template-columns: minmax(max-content, 250px) minmax(max-content, 250px) minmax(max-content, 550px);
      grid-column-gap: 50px;

      &_large {
        grid-template-columns: minmax(max-content, 550px)  minmax(max-content, 550px);
      }
    }

    .search-page__content-controls {
      display: flex;
      gap: 20px;
      margin-top: 30px;

      & > * {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .search-page {
    .search-page__title {
      font: $font-regular-16;
    }

    .search-page__content {
      margin-top: 0;

      & > * {
        margin-top: 0;
      }

      .search-page__content-row {
        display: flex;
        flex-direction: column;

        & > * {
          margin-top: 42px;
        }

        &_large {
          display: flex;
          flex-direction: column;
        }
      }

      .search-page__content-controls {
        gap: 6px;
        margin-top: 53px;

        & > * {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }
}
