@import "src/variables";

.tooltip-hint {
  z-index: 99999;
  max-width: 300px;
  line-height: 180%;
}

.basic-docs-section-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 40px;
  margin-top: 40px;

  img {
    width: 24px;
    height: 24px;
  }

  &_less-space {
    margin-top: 10px;
  }
}

.table-container {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 23px;

  .table {
    display: flex;

    .table__table {
      width: 100%;
      margin-top: 20px;
      border-collapse: collapse;

      thead {
        font: $font-regular-14;
        font-size: 12px;
        color: $color-grey-500;

        tr {
          th {
            padding: 10px 28px;
            font-weight: 400;
            text-align: left;
          }
        }
      }

      tbody {
        position: relative;
        background-color: $color-grey-300;
        font: $font-regular-14;
        font-size: 12px;

        tr {
          border-bottom: 32px transparent solid;

          td {
            text-align: left;
            padding: 22px 28px;
            vertical-align: middle;
          }

          .table__doc-cell {
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
          }

          .table__button-cell {
            // width: 200px;
            padding-top: 7px;
            padding-bottom: 7px;
          }
        }

        .table__label {
          position: absolute;
          display: none;
          font-size: 10px;
          text-align: center;
          color: $color-white;

          &_first {
            top: 0;
            left: 18px;
            display: inline-block;
            background-color: $color-green-700;
          }

          &_last {
            bottom: 0;
            left: 0;
            display: inline-block;
            transform: translateY(100%);
            background-color: $color-black;
          }

          td {
            padding: 5px;
          }
        }
      }
    }

    .table__img {
      display: inline-block;
      width: 25px;
      height: 90px;

      &_none {
        display: none;
      }
    }

    .table__btn {
      margin: 63px 0 0 10px;
    }
  }

  .table-totals {
    width: fit-content;
    margin-top: 10px;
    margin-left: auto;
    display: flex;
    gap: 20px;

    .table-totals__desc {
      color: $color-black;
    }

    .table-totals__title {
      color: $color-grey-500;
    }

    .table-totals__value {
      color: $color-black;
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .basic-docs-section-footer {
    flex-direction: column-reverse;
    row-gap: 40px;
    align-items: flex-start;
  }
}
