@import "src/variables";

.specify-usage {
  display: flex;
  flex-direction: column;
  padding: 0 20px 80px;

  .specify-usage__title {
    margin-top: 47px;
    font: $font-regular-32;
  }

  .specify-usage__doc {
    margin-top: 40px;
  }

  .specify-usage__table-controls {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 40px;

    .specify-usage__table-controls-button {
      img {
        width: 24px;
        height: 24px;
      }
    }
    .specify-usage__table-controls-text {
      color: $color-grey-600;

      & > *:first-child {
        font: $font-regular-12;
      }

      & > *:last-child {
        margin-top: 5px;
        font: $font-regular-16;
      }
    }
  }

  .certificates-navigations-controls-container {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .navigation-button {
      height: 34px;
      width: 34px;
      margin-top: 40px;
      margin-bottom: 20px;

      &__first {
        transform: rotate(90deg);
      }
      &__next {
        transform: rotate(180deg);
      }
      &__last {
        transform: rotate(-90deg);
      }
    }
  }

  .total-zn-used__container {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 2px solid $color-black;
  }

  .table-footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 44px;
    font: $font-regular-16;
    color: $color-black;

    .table-footer__title {
      font: inherit;
      min-width: max-content;
    }

    .table-footer__info {
      display: flex;
      margin-left: auto;
      flex-wrap: wrap;
      gap: 20px;
      font: $font-regular-16;

      .table-footer__info-element {
        display: flex;
        gap: 20px;
      }

      p:first-of-type {
        color: $color-grey-500;
      }

      .table-footer__info-input {
        max-width: 100px;
        padding-bottom: 6px;
        background-color: transparent;
      }

      .disable-input {
        input {
          border-bottom: none !important;
        }
      }
    }
  }

  .zsn-name {
    font: $font-regular-16;
  }

  .certificates-numbering {
    margin-bottom: 40px;
    font: $font-regular-12;
  }

  .dp-for-certificate {
    font: $font-regular-16;
  }

  .specify-usage__table-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;

    .specify-usage__table-form-title {
      font: $font-regular-24;
    }

    & > *:first-child {
      padding-top: 0;
    }
  }
}


@media (max-width: $media-max-width-lg) {
  .table-footer {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 24px;
    gap: 15px;
    font: $font-regular-14;
  }
}

@media (max-width: $media-max-width-md) {
  .specify-usage {
    padding: 0 16px 80px;

    .specify-usage__title {
      margin-top: 47px;
      font: $font-regular-32;
    }

    .specify-usage__table-controls {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;

      & > *:first-child {
        margin-bottom: 30px;
      }
    }

    .specify-usage__table-form {
      margin-top: 20px;
      gap: 20px;

      .specify-usage__table-form-title {
        font: $font-regular-16;
      }

      & > *:first-child {
        padding-top: 0;
      }
    }
  }

  .table-footer {
    gap: 0;
    margin-top: 20px;

    .table-footer__info {
      margin-top: 30px;

      .table-footer__info-element {
        display: grid;
        grid-template-columns: 100px 100px;
        grid-column-gap: 20px;
        gap: 20px;
        font: $font-regular-12;

        input {
          font: $font-regular-12;
        }
      }
    }
  }
}
