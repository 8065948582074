@import "src/variables";

.navigation {
  display: flex;
  flex-direction: column;
  background: $color-green_200;
  padding: 0 20px;

  &-official {
    background: $color-grey-400;
  }

  .navigation-header {
    display: flex;
    padding: 40px 0;
    align-items: flex-start;

    .navigation-header__image {
      padding-right: 5px;
      font: $font-regular-32;
    }

    .navigation-header_no-img {
      display: none;
    }

    .navigation-header__text-wrapper {
      text-align: start;

      .navigation-header__title {
        font: $font-regular-32;
      }

      .navigation-header__subtitle {
        padding-top: 5px;
        padding-left: 2px;
        font: $font-regular-20;
      }
    }

    .navigation-header__logo-wrapper {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      gap: 32px;
      align-items: flex-end;
    }

    &_no-logo {
      width: 60%;

      .navigation-header__logo-wrapper {
        display: none;
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .navigation {
    padding: 0 16px;

    .navigation__breadcrumbs {
      display: none;
    }

    .navigation-header {
      padding: 30px 0 38px;

      .navigation-header__image {
        font: $font-regular-32;
      }

      .navigation-header__text-wrapper {

        .navigation-header__title {
          font: $font-regular-20;
        }

        .navigation-header__subtitle {
          padding-top: 5px;
          padding-left: 2px;
          font: $font-regular-12;
        }
      }

      .navigation-header__logo-wrapper {
        display: none;
      }

      &_no-logo {
        width: 100%;
      }
    }
  }
}
