@import "src/variables";

.document-for-use {
  max-width: 1050px;
  padding: 20px;
  background-color: $color-grey-300;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  &__description-container {
    display: flex;
  }

  &__description-image {
    max-width: 97px;
    max-height: 136px;
    margin: 0 40px 20px 0;
  }

  &__description-files {
    margin: 0 40px 20px 0;
  }

  &__description-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 13.5px 0px;
  }

  &__description-text-row {
    display: flex;
    // align-items: end;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  &__description-text-item {
    width: 25%;
    min-width: 25%;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__description-label {
    margin-bottom: 15px;
    font: $font-regular-12;
    color: $color-grey-500;
  }

  &__description-text {
    font: $font-regular-12;
  }

  &__controls-container {
    display: flex;
    margin-top: 20px;

    &> :not(:last-child) {
      margin-right: 20px;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }

  &__railray-bill-container {
    &>*:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .additional {
    display: flex;
    gap: 20px;

    &>* {
      width: 50%;
      gap: 20px;
      display: flex;
    }

    .additional-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .document-for-use__description-text-item {
        width: 100%;
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .document-for-use {
    padding: 20px 16px;

    &__description-container {
      flex-direction: row-reverse;
      margin-bottom: 30px;
    }

    &__description-image {
      max-width: 50px;
      max-height: 70px;
      margin: 0 0 10px 0;
    }

    &__description-files {
      margin: 0 0 10px 0;
    }

    &__description-text-container {
      padding: 0;
    }

    &__description-text-row {
      flex-wrap: wrap;

      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }

    &__description-text-item {
      width: calc(50% - 10px);
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &__description-label {
      margin-bottom: 5px;
      font: $font-regular-10;
      color: $color-grey-500;
    }

    &__description-text {
      font: $font-regular-12;
    }

    &__controls-container {
      flex-direction: column;
      margin-top: 60px;

      &> :not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      &>* {
        width: max-content;

        &>div {
          margin-right: 10px;
        }
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    &__railray-bill-container {
      &>*:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}