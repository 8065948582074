@import "src/scenes/personal-cabinet-page.module";

.order-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .order-status__status {
    white-space: nowrap;
  }

  p {
    white-space: nowrap;
  }
}
