@import "src/variables";

$checkbox-width: 17px;
$checkbox-height: 17px;

.checkbox {
  position: relative;
  display: block;
  width: $checkbox-width;
  height: $checkbox-height;
  border: 1px solid $color-grey-400;
  cursor: pointer;

  .checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  .checkbox__checkmark {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: $color-white;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 4px;
      top: 0;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  &:hover input~.checkbox__checkmark {
    background-color: $color-grey-300;
  }

  input:checked~.checkbox__checkmark {
    background-color: $color-green-500;
  }
}

.checkbox--disabled {
  cursor: unset;
  opacity: 0.5;

  &:hover input~.checkbox__checkmark {
    background-color: $color-white;
  }
}