@import "src/variables";

.return-waste {
  margin-top: 40px;

  .return-waste__select {
    margin-bottom: 40px;
    max-width: 250px;
  }

  .return-waste__checkbox {
    margin-bottom: 40px;
  }

}

.green-notification {
  background-color: $color-green-200;
}