@import "src/variables";

.certificate-subtitle {
  font-size: 32px !important;
}

.search-tab {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: $media-max-width-lg) {
  .certificate-title {
    font: $font-regular-16 !important;
  }

  .certificate-subtitle {
    font: $font-regular-16 !important;
  }
}
