@import "src/variables";

.document-view__image-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 160px;
  height: 220px;

  & button {
    max-width: 46px;
    max-height: 46px;
    margin-bottom: 10%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .document-view__image-buttons-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    height: auto;
  }

  &:hover {
    & .document-view__image-buttons-container {
      display: flex;
      flex-direction: column;
    }
  }

  .document-view__image {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $media-max-width-lg) {
  .document-view__image-container {
    width: 90px;
    height: 124px;

    & button {
      max-width: 34px;
      max-height: 34px;

      margin-bottom: 10%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .document-view__image {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
