@import "src/variables";

.create-document-page {
  display: flex;
  flex-direction: column;
  padding: 0 20px 80px;

  .create-document-page__title {
    margin-top: 47px;
    font: $font-regular-32;
  }

  .create-document-page__table-form {
    .create-document-page__checkbox-wrapper {
      max-width: 670px;
      margin-top: 40px;
    }

    .create-document-page__table-form-title {
      margin-top: 80px;
      font: $font-regular-20;
    }

    .create-document-page__table-form-reminder {
      margin-top: 40px;
      line-height: 130%;
    }

    .create-document-page__table-form-selects {
      display: flex;
      gap: 40px;

      &>* {
        width: 250px;
      }
    }

    .create-document-page__table-form-table {
      margin-top: 40px;
    }

    .create-document-page__table-form-controls {
      display: flex;
      gap: 40px;
      margin-top: 48px;
    }
  }

  .create-document-page__grade-label {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 21px;
    padding: 4px;
    border-radius: 2px;
    background: $color-grey-400;
    font: $font-regular-10;

    img {
      width: 13px;
      height: 13px;
      cursor: pointer;
    }
  }
}

@media (max-width: $media-max-width-md) {
  .create-document-page {
    padding: 0 16px 80px;

    .create-document-page__title {
      margin-top: 40px;
      font: $font-regular-16;
    }

    .create-document-page__table-form {
      margin-top: 28px;

      .create-document-page__table-form-title {
        font: $font-regular-16;
      }

      .create-document-page__table-form-selects {
        flex-direction: column;
        gap: 26px;

        &>* {
          width: 100%;
        }
      }

      .create-document-page__table-form-table {
        margin-top: 20px;
      }

      .create-document-page__table-form-controls {
        gap: 6px;
        margin-top: 30px;
      }

      .create-document-page__table-form-reminder {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .create-document-page {
    .create-document-page__table-form {
      .create-document-page__table-form-title {
        margin-top: 90px;
      }
    }
  }
}