@import "src/variables";

.info-header {
  gap: 22px;
  font: $font-regular-12;
  border-bottom: 2px solid $color-black;

  .info-header__grey-text {
    color: $color-grey-500;
  }

  .info-header__flex-item {
    display: flex;
    align-items: center;
    gap: 20px;
    width: max-content;
  }

  .info-header__left {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .info-header__clock {
    font: $font-regular-12;
  }

  .info-header__center {
    display: none;
    gap: 10px;
    font: $font-regular-10;
  }

  .info-header__right {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .info-header__right-text {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: max-content;
    }

    .info-header__right-btn {
      display: none;
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .info-header {
    height: $max-header-layout-mobile-height;
    padding: 20px;
    font: $font-regular-10;

    .info-header__left {
      width: max-content;

      .info-header__disappear-part {
        display: none;
      }

      gap: 0;
    }

    .info-header__clock {
      font: $font-regular-10;
    }

    .info-header__clock-text {
      display: none;
    }

    .info-header__center {
      display: inline-block;
    }

    .info-header__flex-item {
      gap: 30px;
    }

    .info-header__statistics-container {
      display: flex;
      align-items: center;

      .info-header__statistics-text {
        margin-right: 32px;
      }
    }
  }
}