@import "src/variables";

.modal-container {
  max-width: 440px;
}

.modal-container-large {
  max-width: 600px;
}

.error-modal {
  display: flex;
  flex-direction: column;

  .error-modal__title {
    text-align: center;
    margin-top: 35px;
    font: $font-regular-24;
  }

  .error-modal__description {
    text-align: center;
    margin: 50px 0 20px;
    font: $font-regular-20;
  }
}