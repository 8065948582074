@import "src/variables";

.form-controls {
  display: flex;
  gap: 40px;
  margin-top: 40px;
}

@media (max-width: $media-max-width-md) {
  .form-controls {
    display: flex;
    gap: 6px;
    margin-top: 30px;
  }
}
