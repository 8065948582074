@import "src/scenes/personal-cabinet-page.module";

.actions-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;

  .personal-cabinet-page__icon-buttons {
    display: flex;
    gap: 20px;
  }

  .personal-cabinet-page__icon-button {
    div {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .actions-container__annul-button {
    display: flex;
    align-items: center;
    padding: 15px 40px;
  }

  .actions-container__number-count {
    font: $font-regular-20;
  }
}