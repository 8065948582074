@import "src/variables";

.product-types-form {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  &__title {
    font: $font-regular-16;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  &__field-container {
    max-width: 610px;
  }
}

.controls-container {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

@media (max-width: $media-max-width-md) {
  .controls-container {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
}
