@import "src/variables";

.modal-container {
  max-width: 1280px;
}

.green {
  background-color: $color-green-300;
}

.primary-document-modal {
  display: flex;
  flex-direction: column;

  .children-head {
    margin: 60px 0 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
  }

  .children-head--full {
    margin: 60px 0 40px;
    display: flex;
  }

  .children-files {
    grid-row-start: span 2;

    &__documen-view {
      width: 120px;
      height: 165px;
    }
  }

  .primary-document-modal__title {
    margin-bottom: 35px;
    font: $font-regular-24;
  }

  .primary-document-modal__main-info-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .primary-document-modal__general-title {
    font-size: 24px;
  }

  .primary-document-modal__general-actions {
    grid-column: 1 / span 1;
    display: flex;
    gap: 40px;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 34px;
  }

  .primary-document-modal__general-actions-button {
    width: max-content;
  }

  .primary-document-modal__main-info-single-wrapper {
    display: flex;
    flex-direction: column;

    &:last-child {
      padding-right: 0px;
    }
  }

  .primary-document-modal__switch-wrapper {
    display: flex;
    align-items: center;

    & > *:last-child {
      margin-left: 10px;
    }
  }

  .primary-document-modal__switch-title {
    font: $font-regular-16;
  }

  .primary-document-modal__controls-button {
    img {
      width: 24px;
      height: 24px;
    }
  }

  .primary-document-modal__main-info-single-medium {
    width: 50%;
  }

  .primary-document-modal__main-info-single-large {
    width: 100%;
  }

  .primary-document-modal__main-info-label {
    margin-bottom: 5px;
    font: $font-regular-12;
    color: $color-grey-600;
  }

  .primary-document-modal__main-info-text {
    max-width: 400px;
    font: $font-regular-16;
    color: $color-grey-600;
  }

  .primary-document-modal__total-block {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
    gap: 40px;
    border-top: 2px $color-black solid;
  }

  .primary-document-modal__general-controls {
    margin-top: 60px;
    display: flex;
    align-items: center;

    & > * {
      margin-right: 20px;
    }
  }

  .primary-document-modal__total-block-header {
    font: $font-regular-16;
    margin-right: auto;
  }

  .primary-document-modal__total-block-unit-wrapper {
    display: flex;
    align-items: center;
  }

  .primary-document-modal__total-block-unit-label {
    margin-right: 20px;
    font: $font-regular-16;
    color: $color-grey-500;
  }

  .primary-document-modal__total-block-unit-text {
    font: $font-regular-16;
  }

  .primary-document__return-waste {
    margin-top: 80px;
  }
}

@media (max-width: $media-max-width-lg) {
  .primary-document-modal {
    .processed {
      font: $font-regular-10;
    }

    .primary-document-modal__title {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 500;
      font: $font-regular-16;
    }

    .children-head {
      grid-template-columns: 1fr;
    }

    .children-files {
      &__documen-view {
        width: 140px;
        height: 195px;
      }
    }

    .primary-document-modal__main-info-label {
      font: $font-regular-10;
    }

    .primary-document-modal__main-info-text {
      font: $font-regular-12;
    }

    .primary-document-modal__main-info-container {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 25px;
    }

    .primary-document-modal__main-info-single-wrapper {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .primary-document-modal__switch-wrapper {
      margin-bottom: 20px;

      & > *:last-child {
        margin-left: 10px;
      }
    }

    .primary-document-modal__switch-title {
      font: $font-regular-14;
    }

    .primary-document-modal__general-actions {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    .primary-document-modal__main-info-single-small {
      width: 100%;
    }

    .primary-document-modal__main-info-single-medium {
      width: 100%;
    }

    .primary-document-modal__total-block {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 60px;
      margin-top: 20px;
    }

    .primary-document-modal__total-block-header {
      margin-bottom: 30px;
      font: $font-regular-14;
    }

    .primary-document-modal__total-block-unit-wrapper {
      width: 100%;
      margin-bottom: 20px;
    }

    .primary-document-modal__total-block-unit-label {
      width: 15%;
      margin-right: 20px;
      font: $font-regular-12;
    }

    .primary-document-modal__total-block-unit-text {
      width: 100px;

      padding-bottom: 6px;
      border-bottom: 2px solid $color-black;
      font: $font-regular-12;
    }

    .primary-document-modal__general-controls {
      flex-direction: column;
      align-items: flex-start;

      & > * {
        margin-right: 0;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
