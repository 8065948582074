@import "src/variables";

.certificates-nav-btn-indicator {
  display: flex;
  align-items: center;
  column-gap: 13px;

  .certificates-nav-btn__round-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    background: $color-red-200;
    font: $font-regular-20;
    border-radius: 50%;
    border: 1px solid $color-black;
  }
}

.certificates-nav-btn {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  padding-bottom: 0 !important;

  .certificates-nav-btn__img {
    width: 25px;
    height: 34px;
  }
}

.certificate__tree-file-loader {
  align-self: flex-start;
  margin-bottom: 65px;
}

.certificate__loader-background {
  &:hover {
    border-color: $color-grey-300;
    background: linear-gradient($color-grey-300, $color-grey-300) padding-box,
      $color-gradient-outlined border-box;
  }
}

@media (max-width: $media-max-width-lg) {
  .certificate__tree-file-loader {
    align-self: flex-start;
    margin-bottom: 65px;
  }

  .certificate__tree-file-loader {
    width: 90px;
    height: 124px;
    margin-bottom: 52px;
    line-break: anywhere;
  }
}
