@import "src/scenes/subject/certificates/certificates-common-styles.module";

.create-certificate {
  padding-bottom: 100px;

  .create-certificate__tree-file-loader {
    align-self: flex-start;
    margin-bottom: 65px;
  }
}

.declined-page-tabs {
  menu {
    align-items: baseline;
  }
}

.create-certificate__create-btn {
  margin-top: 40px;
  margin-left: 80px;
}

.error-wrapper {
  padding: 40px 80px 20px;
}

.concern-section {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .concern-section__title {
    padding-bottom: 0;
  }

  .concern-section__concern {
    display: flex;
    flex-direction: column;
    gap: 40px;

    h3 {
      font-weight: 400;
      font: $font-regular-20;
    }
  }
}

.annulment-section {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__annulment {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__attached-files-container {
    display: flex;
    gap: 40px;
    align-items: center;
  }

  .annulment-section__title {
    padding-bottom: 0;
    font-size: 24px;
  }
}

@media (max-width: $media-max-width-lg) {
  .create-certificate__create-btn {
    margin: 20px 5px 0;
    padding: 12px 40px !important;
  }

  .concern-section {
    gap: 30px;

    .concern-section__concern {
      gap: 20px;

      h3 {
        font: $font-regular-14;
      }
    }
  }

  .annulment-section {
    gap: 30px;

    &__annulment {
      gap: 20px;
    }

    &__attached-files-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .annulment-section__title {
      font-size: 14px;
    }
  }

  .error-wrapper {
    padding: 20px 16px 10px;
  }
}
