@import "../../variables";

.create-doc {
  @mixin grid-mixin {
    display: grid;
    grid-template-columns: minmax(min-content, 550px) minmax(min-content, 250px) 1fr;
    grid-column-gap: 50px;
  }

  margin-top: 40px;

  @include grid-mixin;
  grid-template-rows: repeat(5, max-content);

  .create-doc__form-row {
    @include grid-mixin;
    grid-template-columns: minmax(min-content, 550px) minmax(min-content, 250px);
    grid-column: 1 / span 2;
    margin-top: 26px;

    &:first-child {
      margin-top: 0;
    }

    &_first {
      grid-template-columns: repeat(4, minmax(min-content, 250px));
      grid-column-start: 1;
      grid-column-end: 4;
    }

    &_inputs {
      margin-top: 46px;
    }
  }

  .create-doc__form-row-additional {
    @include grid-mixin;
    grid-template-columns: minmax(min-content, 550px) minmax(min-content, 550px) 0fr;
    grid-column: 1 / span 4;
    margin-top: 26px;

    & > * {
      display: flex;
      gap: 20px;
    }

    .additional-text {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .create-doc__form-file-loader {
    margin-top: 26px;
    grid-row: 2 / span 3;
    grid-column: 3 / span 2;
  }

  .create-doc__documen-view {
    width: 120px;
    height: 165px;
    margin-top: 26px;
    grid-row: 2 / span 3;
    grid-column: 3 / span 2;
  }

  .create-doc__text-item {
    color: $color-grey-600;

    & > :first-child {
      font: $font-regular-12;
    }

    & > :last-child {
      margin-top: 5px;
      font: $font-regular-16;
      white-space: break-spaces;
      word-break: break-word;
    }
  }
}

@media (max-width: $media-max-width-xl) {
  .create-doc {
    .create-doc__form-row {
      grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr);

      &_first {
        & > *:not(:nth-child(-n + 2)) {
          margin-top: 26px;
        }
      }
    }

    .create-doc__form-file-loader {
      margin-top: 40px;
      grid-row: unset;
      grid-column: 1 / span 1;
    }

    .create-doc__documen-view {
      margin-top: 40px;
      grid-row: unset;
      grid-column: 1 / span 1;
    }
  }
}

@media (max-width: $media-max-width-md) {
  .create-doc {
    grid-template-columns: 1fr max-content;
    grid-template-rows: repeat(6, max-content);
    grid-column-gap: 0;
    margin-top: 26px;

    .create-doc__form-row {
      grid-template-columns: 1fr;
      grid-column: 1;
      margin-top: 34px;

      &:first-child {
        margin-top: 0;
      }

      & > *:not(:first-child) {
        margin-top: 34px;
      }

      &_first {
        grid-template-columns: 1fr;
      }

      &_inputs {
        margin-top: 56px;
      }
    }

    .create-doc__form-file-loader {
      width: 140px;
      height: 196px;
    }

    .create-doc__documen-view {
      width: 140px;
      height: 196px;
    }

    .create-transition-doc__text-item {
      & > :first-child {
        font: $font-regular-10;
      }

      & > :last-child {
        font: $font-regular-12;
      }
    }

    .create-doc__text-item {
      & > :first-child {
        font: $font-regular-10;
      }

      & > :last-child {
        font: $font-regular-12;
      }
    }
  }
}
