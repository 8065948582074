@import "src/variables";

.controls-container {
  display: flex;

  button:first-child {
    margin-right: 20px;
  }
}



@media (max-width: $media-max-width-md) {
  .controls-container {
    flex-direction: column;

    &>* {
      width: max-content;

      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
}