@import "src/variables";

.add-remark-modal {
  max-width: 720px;

  &__title {
    margin-bottom: 40px;
  }

  &__controls-container {
    display: flex;

    &> :first-child {
      margin-right: 20px;
    }
  }
}

.describe-modal {
  background: $color-white;

  &__form {
    margin-bottom: 55px;

    &>*:first-child {
      margin-bottom: 40px;
    }
  }
}





@media (max-width: $media-max-width-lg) {
  .add-remark-modal {
    &__title {
      margin-bottom: 30px;
      font: $font-regular-16;
    }

    &__controls-container {
      flex-direction: column;

      &> :first-child {
        margin-right: 0;
        margin-bottom: 30px;
      }

      &>* {
        width: max-content;
      }
    }
  }
}