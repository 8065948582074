@import "src/variables";

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $modals-z-index;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;
  padding: 15px;
  background: rgba(black, 0.8);
}

.react-modal {
  background: white;
  max-width: 1280px;
  width: 100%;
  position: relative;
  margin: auto;
  height: auto;

  .react-modal__wrapper {
    max-width: 1280px;
  }

  .react-modal__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }

  .react-modal__body {
    padding: 40px;
  }
}

@media (max-width: $media-max-width-lg) {
  .react-modal {

    .react-modal__close-btn {
      top: 10px;
      right: 10px;
    }

    .react-modal__body {
      padding: 40px 15px
    }
  }
}
