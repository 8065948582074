$color_main_bg: #f5f5f5;
$color-white: #ffffff;
$color-black: #000000;

$color-green-200: #ecf8ed;
$color-green-300: #c6e9ca;
$color-green-400: #8ed395;
$color-green-500: #42b64e;
$color-green-600: #35923e;
$color-green-700: #286d2f;
$color-green-800: #143717;

$color-grey-300: #e7eef3;
$color-grey-400: #d0d6db;
$color-grey-500: #8b9094;
$color-grey-600: #5c5f61;
$color-grey-700: #464849;

$color-yellow: #ffd600;
$color-yellow-50: #fff4d7;

$color-red-100: #feeceb;
$color-red-200: #fcc7c3;
$color-red-300: #f88e86;
$color-red-400: #f6695e;
$color-red-500: #f44336;

$color-gradient-outlined: linear-gradient(to right,
  #c3aab2,
  #99eecc,
  #80c0c8,
  #4b8bfa);
$color-gradient-hover: linear-gradient(68.94deg,
  #c3aab2 -4.77%,
  #99eecc 46.72%,
  #80c0c8 90.23%,
  #4b8bfa 134.46%);
$color-gradient-3: linear-gradient(68.94deg,
  #6372ff -4.77%,
  #925182 46.72%,
  #b19956 90.23%,
  #d97d34 134.46%);
$color-gradint-4: linear-gradient(180deg,
  #b9d19e 0%,
  #ecaaaf 26.04%,
  #9889db 50.52%,
  #757aed 68.75%,
  #2833d1 100%);

$transition-duration: 0.4s;

$font-h1: normal 700 36px e_UkraineHead,
sans-serif;
$font-h2: normal 700 32px e_UkraineHead,
sans-serif;
$font-h3: normal 700 24px e_UkraineHead,
sans-serif;
$font-h4: normal 700 20px e_UkraineHead,
sans-serif;
$font-h5: normal 700 16px e_UkraineHead,
sans-serif;
$font-h6: normal 700 14px e_UkraineHead,
sans-serif;

$font-bold-36: normal 700 36px e_Ukraine_Bold,
sans-serif;
$font-bold-32: normal 700 32px e_Ukraine_Bold,
sans-serif;
$font-bold-24: normal 700 24px e_Ukraine_Bold,
sans-serif;
$font-bold-20: normal 700 20px e_Ukraine_Bold,
sans-serif;
$font-bold-16: normal 700 16px e_Ukraine_Bold,
sans-serif;
$font-bold-14: normal 700 14px e_Ukraine_Bold,
sans-serif;
$font-bold-12: normal 700 12px e_Ukraine_Bold,
sans-serif;

$font-regular-48: normal 400 48px e_Ukraine_Regular,
sans-serif;
$font-regular-36: normal 400 36px e_Ukraine_Regular,
sans-serif;
$font-regular-32: normal 400 32px e_Ukraine_Regular,
sans-serif;
$font-regular-24: normal 400 24px e_Ukraine_Regular,
sans-serif;
$font-regular-20: normal 400 20px e_Ukraine_Regular,
sans-serif;
$font-regular-16: normal 400 16px e_Ukraine_Regular,
sans-serif;
$font-regular-14: normal 400 14px e_Ukraine_Regular,
sans-serif;
$font-regular-13: normal 400 13px e_Ukraine_Regular,
sans-serif;
$font-regular-12: normal 400 12px e_Ukraine_Regular,
sans-serif;
$font-regular-11: normal 400 11px e_Ukraine_Regular,
sans-serif;
$font-regular-10: normal 400 10px e_Ukraine_Regular,
sans-serif;

$font-docs-24: normal 700 24px "Times New Roman",
sans-serif;

$max-page-width: 1440px;
$max-header-height: 82px;
$max-header-layout-mobile-height: 74px;
$max-header-mobile-height: 60px;

$media-max-width-xs: 375px;
$media-max-width-sm: 500px;
$media-max-width-md: 768px;
$media-max-width-lg: 992px;
$media-max-width-xl: 1300px;

$table-header-z-index: 1;
$navigation-z-index: 6;
$header-z-index: 10;
$modals-z-index: 100;
$tooltips-z-index: 1000;