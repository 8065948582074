@import "src/components/tables/common-table-styles.module";

.table-wrapper {
  .table-scroll-wrapper {
    .table {
      &_common {
        thead {
          color: $color_grey-500;

          tr {
            th {
              padding: 0 $column-gap 15px $column-gap;
              text-align: left;
              font: $font-regular-12;

              &:first-child {
                padding-left: 6px;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }
        }

        tbody {
          color: $color-black;

          &>*:nth-child(odd) {
            background-color: $color-grey-300;
          }

          &>*:nth-child(even) {
            background-color: $color-white;
          }

          .table__row_green {
            background-color: $color-green-300;
          }

          tr {
            td {
              vertical-align: middle;
              padding: 14px $column-gap;
              text-align: left;
              font: $font-regular-12;

              &:first-child {
                padding-left: 19px;
              }

              &:last-child {
                padding-right: 19px;
              }
            }
          }
        }
      }

      &_row-gap {
        $column-gap: 5px;
        border-collapse: separate;
        border-spacing: 0 20px;

        thead {
          tr {
            th {
              padding: 0 $column-gap 0 $column-gap;
              text-align: center;
              color: $color-grey-500;
              font: $font-regular-12;

              &:first-child {
                padding-left: $column-gap;
              }

              &:last-child {
                padding-right: $column-gap;
              }
            }
          }
        }

        tbody {
          color: $color-black;

          &>* {
            background-color: $color-grey-300;
          }

          &>*:nth-child(2n) {
            background-color: $color-grey-300;
          }

          tr {
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            cursor: pointer;

            &> :first-child {
              border-left: 1px solid transparent;
            }

            &> :last-child {
              border-right: 1px solid transparent;
            }

            &>* {
              padding-top: 14px;
            }

            &:hover {
              background: linear-gradient(to left, $color-white, $color-white) padding-box,
                $color-gradient-outlined border-box;
            }

            td {
              padding: 34px $column-gap;
              border-right: 1px solid $color-grey-400;
              font: $font-regular-12;
              text-align: center;
              vertical-align: middle;

              // status center
              h3 {
                margin: 0 auto;
              }

              &:first-child {
                color: $color-grey-500;
                font: $font-regular-20;
              }

              &:last-child {
                border-right: 1px solid transparent;
              }
            }
          }
        }
      }

      &_row-gapsinio {
        @extend .table_row-gap;

        thead {
          tr {
            th {
              font: $font-regular-11;
            }
          }
        }

        tbody {
          tr {
            td {
              font: $font-regular-11;
            }
          }
        }
      }

      &_simple {
        thead {
          color: $color_grey-500;
          border: 1px solid $color-grey-400;

          tr {
            th {
              padding: 15px;
              text-align: center;
              font: $font-regular-12;
              border: 1px solid $color-grey-400;
            }
          }
        }

        tbody {
          color: $color-black;

          &>* {
            background-color: $color-white;
            border: 1px solid $color-grey-400;
          }

          .table__row_green {
            background-color: $color-green-300;
          }

          tr {
            td {
              vertical-align: middle;
              padding: 14px $column-gap;
              text-align: center;
              font: $font-regular-12;
              border: 1px solid $color-grey-400;

              &:first-child {
                padding-left: 19px;
              }

              &:last-child {
                padding-right: 19px;
              }
            }
          }
        }
      }
    }

    .table-footer__info {
      margin-left: auto;
      flex-wrap: wrap;

      .table-footer__info-element {
        display: flex;
        gap: 20px;
      }
    }
  }
}

@media (max-width: $media-max-width-md) {
  .table-footer {
    gap: 0;
    margin-top: 20px;

    .table-footer__info {
      margin-top: 30px;

      .table-footer__info-element {
        display: grid;
        grid-template-columns: 100px 100px;
        grid-column-gap: 20px;
        gap: 20px;
        font: $font-regular-12;

        input {
          font: $font-regular-12;
        }
      }
    }
  }
}