@import "src/variables";

.get-certificate__questions {
  ul {
    margin-left: 20px;
    li {
      list-style-type: initial;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  ol {
    margin-left: 20px;
    li {
      list-style-type: decimal;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.page-container {
  padding: 77px 80px;
}

.notifications-wrapper {
  max-width: 840px;
}

.notifications-list {
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
}

.title {
  font: $font-regular-32;
  margin-bottom: 80px;
}

.questions-container {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.question-counter {
  font: $font-regular-16;
}

.question-text {
  font: $font-regular-16;
}

.video-container {
  margin-top: 50px;
}

.image-container {
  width: 100%;
  margin-top: 50px;
  & img {
    max-width: 100%;
    width: auto;
    max-height: 400px;
    height: auto;
    object-fit: contain;
  }
}

@media (max-width: $media-max-width-lg) {
  .page-container {
    padding: 40px 16px;
  }
}
