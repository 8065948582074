@import "src/scenes/personal-cabinet-page.module";

.personal-cabinet-page {
  padding: 40px 20px 101px 20px;

  .personal-cabinet-page__title {
    margin-bottom: 30px;
    font: $font-regular-24;
  }

  .search-container {
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;

    .search-container__input {
      width: auto;
    }

    input {
      max-width: 250px;
      height: fit-content;
      margin-bottom: 8px;
    }

    .search-container__search-button {
      padding: 15px 40px;
      margin-left: 15px;
      margin-right: 40px;
    }

    .advanced-wrapper {
      display: flex;

      .advanced-wrapper__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 250px;
        border: 0;
        border-radius: 0;
        border-bottom: 2px dashed #000000;
        padding: 0 5px 8px 0;
        margin-bottom: 8px;
        font: $font-regular-16;

        &:hover {
          border: 0;
          border-radius: 0;
          border-bottom: 2px dashed #000000;
          background: 0;
        }
      }

      .advanced-wrapper__advanced {
        transform: rotate(180deg);
      }
    }
  }

  .search-page {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;

    .search-page__content-row {
      display: grid;
      grid-template-columns:
        minmax(max-content, 250px) minmax(max-content, 250px) minmax(max-content, 550px);
      grid-column-gap: 50px;

      &:not(:first-child) {
        margin-top: 50px;
      }

      &_large {
        grid-template-columns: minmax(max-content, 550px) minmax(max-content,
            550px);
      }

      &_large_small {
        grid-template-columns: minmax(max-content, 550px) minmax(max-content,
            250px);
      }

      &_similar {
        grid-template-columns: minmax(max-content, 250px) minmax(max-content,
            250px) minmax(max-content,
            250px);
      }

      .single-field {
        max-width: 550px;
      }
    }

    .search-page__content-controls {
      display: flex;
      gap: 20px;
      margin-top: 30px;

      &>* {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .personal-cabinet-page {
    .personal-cabinet-page__title {
      margin-top: 50px;
    }

    .search-container {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 0px;

      input {
        min-width: 100%;
        margin-bottom: 20px;
      }

      .search-container__search-button {
        margin: 0px 0px 60px;
      }

      .advanced-wrapper {
        position: relative;
        min-width: 100%;

        .advanced-wrapper__button {
          min-width: 100%;
        }
      }
    }

    .search-page {
      margin-top: 0;
      margin-bottom: 0;

      .search-page__content-row {
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
          margin-top: 0;
        }

        &>* {
          margin-top: 42px;
        }

        &_large {
          display: flex;
          flex-direction: column;
        }

        .single-field {
          max-width: none;
        }
      }

      .search-page__content-controls {
        flex-direction: column;
        gap: 6px;
        margin-top: 53px;

        &>* {
          width: max-content;
          padding-top: 12px;
          padding-bottom: 12px;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}