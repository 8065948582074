@import "src/variables";

$column-gap: 10px;

.table-wrapper {
  padding: 8px 0 0 0;

  .table-scroll-wrapper {
    overflow-x: auto;

    .table {
      width: 100%;
      border-collapse: collapse;
    }
  }

  .table-controls {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    margin-top: 24px;
  }

  .table-footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 44px;
    font: $font-regular-16;
    color: $color-black;

    .table-footer__title {
      font: inherit;
    }

    .table-footer__info {
      display: flex;
      gap: 20px;
      font: $font-regular-16;

      p:first-of-type {
        color: $color-grey-500;
      }

      .table-footer__info-input {
        max-width: 100px;
        padding-bottom: 6px;
        background-color: transparent;
      }

      .disable-input {
        input {
          border-bottom: none !important;
        }
      }
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .table-wrapper {
    .table-controls {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 38px;
      margin-top: 24px;
    }

    .table {
      width: 100%;
      font: $font-regular-10;
      border-collapse: collapse;
    }

    .table-footer {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 24px;
      gap: 15px;
      font: $font-regular-14;
    }
  }
}