@import 'src/variables';

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 50px 0 100px;

  .error-page__title {
    margin: 30px 0 0 0;
    font-size: 120px;
  }

  .error-page__desc {
    margin-top: 20px;
    font-size: 16px;
  }

  .error-page__btn {
    margin-top: 60px;
  }

}

