@import "src/variables";

.doc-section {
  .doc-section__texts {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .doc-section__image-container {
    position: relative;
    width: 120px;
    height: 165px;

    & button {
      max-width: 46px;
      max-height: 46px;
      margin-bottom: 10%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .doc-section__image-buttons-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      height: auto;
    }

    &:hover {
      & .doc-section__image-buttons-container {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .doc-section__image {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &_text-right {
    display: flex;
    gap: 30px;
  }

  &_text-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .doc-section__texts {
      margin-top: 15px;
      text-align: start;
    }

    .doc-section__bottom-text > * {
      font: $font-regular-12;
      font-weight: 400;
    }
  }

  &_text-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 450px;
  }
}

@media (max-width: $media-max-width-lg) {
  .doc-section {
    .doc-section__image-container {
      width: 90px;
      height: 124px;

      & button {
        max-width: 34px;
        max-height: 34px;

        margin-bottom: 10%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .doc-section__image {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    &_text-right {
      display: inline-block;

      .doc-section__texts {
        margin-top: 1px;
        gap: 10px;
      }
    }

    &_text-bottom {
      .doc-section__texts {
        margin-top: 1px;
        gap: 10px;
      }
    }

    &_text-left {
      max-width: 340px;
    }
  }
}
