@import "src/variables";

.section-text {
  font: $font-regular-16;
  color: $color-grey-600;

  .section-text__title {
    font: $font-regular-12;
  }

  .section-text__text,
  .section-text__sub-text {
    padding-top: 5px;
  }
}

@media (max-width: $media-max-width-lg) {
  .section-text {

    .section-text__title {
      font: $font-regular-10;
    }

    .section-text__text {
      font: $font-regular-12;
    }
  }
}
