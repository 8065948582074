@import "variables";

.status {
  display: block;
  min-height: 18px;
  height: max-content;
  padding: 2px 4px;
  max-width: max-content;
  font: $font-regular-11;
  line-height: 16px;
  text-transform: uppercase;
  word-break: keep-all;
}
