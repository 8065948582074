@import "src/variables";


.content {
  width: 100%;
  max-width: 865px;
  margin: $max-header-height auto 260px;
  padding-top: 45px;
}

.controls {
  margin-top: 48px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

@media (max-width:885px) {
  .content {
    max-width: calc(100% - 20px);
    margin: $max-header-height 10px 120px;
  }
}