@import "src/variables";

.section {
  padding: 40px 20px;

  &_grey {
    background-color: $color-grey-300;
  }

  &_white {
    background-color: $color-white;
  }

  .section__title {
    max-width: 900px;
    padding-bottom: 40px;
    color: $color-black;
    font-weight: 500;
  }
}

@media (max-width: $media-max-width-lg) {
  .section {
    padding: 30px 16px;

    .section__title {
      padding-bottom: 30px;
    }
  }
}