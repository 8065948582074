@use "sass:math";
@import "src/variables";

.e-doc-button {
  position: relative;
  border: none;
  background: transparent;
  cursor: pointer;

  .e-doc-button__image {
    width: 38px;
    height: 54px;
  }
}

.e-doc-button__tooltip {
  z-index: $tooltips-z-index;

  .e-doc-button__tooltip-container {
    .e-doc-button__tooltip-title {
      margin-bottom: 10px;
      text-align: center;
      font: $font-regular-14;
    }

    .e-doc-button__tooltip-doc-section {
      align-items: center;

      img {
        width: 82px;
        height: 115px;
      }
    }

    .e-doc-button__tooltip-section-doc-container {
      display: flex;
      justify-content: center;
    }

    .e-doc-button__tooltip-image-container {
      width: inherit;
      height: auto;
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .e-doc-button__tooltip {
    .e-doc-button__tooltip-container {
      .e-doc-button__tooltip-image-container {
        height: 124px;
      }
    }
  }
}
