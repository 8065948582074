@import "variables";

.container-border {}

.container {
  margin-bottom: 60px;
  padding: 20px 20px 40px;
  display: block;
  border: 2px solid black;
  border-image: linear-gradient(to right, #6372ff, #925182, #b19956, #d97d34) 1;

  .container__title {
    font: $font-regular-16;
    line-height: 40px;
  }

  .container__info {
    margin: 20px 0;
    display: flex;
    gap: 50px;

    .info {
      flex: 1;

      .info__title {
        text-wrap: nowrap;
        margin-bottom: 30px;
        font: $font-regular-10;
        color: $color-grey-500;
      }

      .info__value {
        font: $font-regular-12;
      }
    }

    .info-long {
      @extend .info;
      width: 240px;
      flex: unset;
    }
  }
}