.set-application-type {
  .set-application-type__form {
    .set-application-type__form-radio {
      margin-bottom: 22px;
    }

    .set-application-type__form-btn {
      margin-top: 40px;
    }

    .set-application-type__form-sub {
      margin-top: 40px;
    }

    .set-application-type__form-btns {
      display: flex;
      gap: 20px;
    }

  }

  .warning {
    margin: 0 0 40px;
  }

  .fields-container--locked {
    pointer-events: none;

    .set-application-type__form-radio {
      opacity: .5;
    }
  }
}