@import "src/variables";

.round-menu-button-wrapper {
  position: relative;
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: $color-grey-300;
  cursor: pointer;

  %center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .round-menu-button__text {
    @extend %center;
    background: transparent;
    font: $font-regular-20;
    font-size: 20px;
    color: $color-black;
  }

  .round-menu-button__images {
    @extend %center;
    display: flex;
    flex-direction: column;
    gap: 3px;

    &>* {
      width: 25px;
      height: 5px;
    }
  }

  .round-menu-button__close-image {
    width: 100%;
    height: 100%;
  }
}