@import "src/variables";

.zsn-doc-view {
  padding: 20px;
  border: 2px solid $color-grey-500;
  width: 100%;
  background: $color-white;

  .zsn-doc-view__content {
    display: grid;
    grid-template-columns: max-content max-content 1fr;

    .zsn-doc-view__checkbox {
      grid-column: 1;
      margin-right: 0;
      align-items: start;
    }

    .zsn-doc-view__image {
      grid-column: 2;
      width: 43px;
      height: 60px;
      position: relative;

      img {
        width: 100%;
        height: auto;
      }
    }

    .zsn-doc-view__text-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 30px;
      grid-column: 3;
      margin-left: 30px;
    }
  }

  .zsn-doc-view__text-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    width: min-content;
    font: $font-regular-12;

    &>*:first-child {
      display: flex;
      align-items: center;
      height: 35px;
      color: $color-grey-500;
    }
  }
}

@media (max-width: $media-max-width-md) {
  .zsn-doc-view {
    width: 100%;

    .zsn-doc-view__content {
      grid-template-columns: 1fr 1fr min-content;
      grid-template-rows: max-content max-content;
      display: grid;

      .zsn-doc-view__checkbox {
        grid-column: 1;
        grid-row: 1;
        margin-right: 0;
      }

      .zsn-doc-view__image {
        grid-column: 3;
        grid-row: 2;
        justify-self: end;
      }

      .zsn-doc-view__text-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column: 1 / span 2;
        grid-row: 2;
        margin-left: 0;
      }
    }
  }
}