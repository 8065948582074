@import "src/variables";

.personal-cabinet-page {
  padding: 40px 20px 101px 20px;

  .personal-cabinet-page__status {
    margin-bottom: 12px;
  }

  .personal-cabinet-page__table-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .personal-cabinet-page__icon-button {
    div {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.actions-container__table-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $color-red-200;
  font: $font-regular-20;
}

@media (max-width: $media-max-width-lg) {
  .personal-cabinet-page {
    padding: 40px 17px 262px 17px;
  }
}
