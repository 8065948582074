@import "src/variables";

.navigation {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background: $color-grey-300;

  .navigation__office-status {
    line-height: 130%;
    width: fit-content;
    padding: 5px 20px;
    background-color: black;
    color: white;
    font: $font-regular-10;
  }

  .navigation-header {
    display: flex;
    align-items: flex-start;
    padding: 50px 0;

    .navigation-header__image {
      padding-right: 20px;
      font: $font-regular-32;
    }

    .navigation-header__text-wrapper {
      text-align: start;

      .navigation-header__title {
        font: $font-regular-20;
      }

      .navigation-header__subtitle {
        font: $font-regular-20;
      }
    }

    .navigation-header__logo-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex: 1;
      gap: 32px;
    }
  }

  .navigation__tabs-wrapper {
    .navigation__tabs {
      display: flex;
      gap: 50px;
      list-style: none;

      overflow-x: auto;

      .navigation__tab {
        white-space: nowrap;
        min-width: max-content;
        padding-bottom: 10px;
        color: $color-black;

        &_active {
          border-bottom: 2px solid $color-black;
        }

        &--bullet {
          width: 26px;
          height: 26px;
          position: absolute;
          font: $font-regular-13;
          background-color: $color-green-600;
          border-radius: 15px;
          color: $color-white;
          line-height: 26px;
          text-align: center;
          transform: translate(-40%, -60%);
        }
      }

      .navigation__search-tab {
        display: flex;
        align-items: flex-start;
        gap: 15px;

        &:first-child {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .navigation {
    padding: 0 16px;
    min-height: 144px;

    .navigation__breadcrumbs {
      display: none;
    }

    .navigation-header {
      padding: 8px 0 66px;

      .navigation-header__image {
        font: $font-regular-32;
      }

      .navigation-header__text-wrapper {
        .navigation-header__title {
          font: $font-regular-20;
        }

        .navigation-header__subtitle {
          font: $font-regular-20;
        }
      }

      .navigation-header__logo-wrapper {
        display: none;
      }
    }

    .navigation__tabs-wrapper {
      position: absolute;
      bottom: 0;
      max-width: calc(100vw - 32px);
      overflow-x: auto;

      .navigation__tabs {
        .navigation__tab {
          padding-top: 20px;
          position: relative;
        }
      }
    }
  }
}