@import "src/variables";

.back-header {
  background-color: $color-white;
  font: $font-regular-12;
  border-bottom: 2px $color-black solid;

  .back-header__center {
    display: none;
    gap: 10px;
    font: $font-regular-10;

    .back-header__center-text {
      color: $color-grey-500;
    }
  }

  .back-header__right {
    .back-header__right-btn {
      display: none;
    }
  }
}

@media (max-width: $media-max-width-lg) {
  .back-header {
    padding: 20px;
    font: $font-regular-10;

    .back-header__disappear-part {
      display: none;
    }

    .back-header__center {
      display: flex;
      align-items: center;
    }

    .back-header__right {
      display: inline-block;

      .back-header__right-btn {
        display: inline-block;
      }
    }
  }
}
