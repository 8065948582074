@import "src/variables";

.form-layout {
  padding: 10px 20px 40px;

  .navigation__breadcrumbs {
    margin-bottom: 40px;
  }
}

@media (max-width: $media-max-width-lg) {
  .form-layout {
    padding: 40px 16px;
  }
}