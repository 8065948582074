@import "src/variables";

.table__input {
  padding-bottom: 1px;
  font: $font-regular-10;
  color: $color-black;

  &.table__input--has-value {
    .select__control {
      .select__value-container {
        border-bottom: none;
      }
    }
  }

  &.table__input--multi-select {
    &.table__input--has-value {
      .select__control {
        .select__value-container {
          border-bottom: none;
        }
      }
    }

    .select__control {
      flex-wrap: nowrap;
      align-items: center;
      max-height: max-content;

      .select__value-container {
        padding: 0;
        width: max-content;
        max-width: 100px;
        min-width: 0;
        margin-right: 8px;
        border-bottom: 2px solid black;
        font: $font-regular-10;
      }
    }

    &.table__input--searchable {
      &.table__input--has-value {
        .select__control {
          .select__value-container {
            .select__input-container {
              display: flex;
              width: min-content;
            }
          }
        }
      }
    }
  }

  .select__control {
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 0 !important;
    border-bottom: none !important;

    .select__value-container {
      padding: 0;
      min-width: max-content;
      margin-right: 8px;
      border-bottom: 2px solid black;
      font: $font-regular-10;

      &--has-value {}
    }
  }

  .select__indicators {
    width: 20px;
    height: 20px;
    align-self: flex-end;

    .select__dropdown-indicator {
      right: 0;
    }
  }
}

.table__input-select {
  input {
    left: 0;
  }
}

.table__autocomplete {
  .select__input-container {
    height: unset !important;
  }
}