@import "src/variables";
@import "src/components/tables/common-table-styles.module";

.table-wrapper {
  padding: 0;

  :global(.field-error-message) {
    position: static;
  }

  .table-scroll-wrapper {
    overflow-y: auto;
    max-height: calc(100vh - $max-header-height - 240px);


    .table {
      border: 1px solid transparent;

      &_editable {
        $column-gap: 2px;
        border-collapse: collapse;

        thead {
          position: sticky;
          top: 0;
          z-index: $table-header-z-index;

          &>*:nth-child(even) {
            background-color: $color-grey-300;
          }

          &>*:nth-child(odd) {
            background-color: $color-white;
          }

          tr {
            &>*:last-child {
              &:after {
                content: "";
                position: absolute;
                top: 0;
                right: -1px;
                height: 105%;
                margin: -2px 0;
                border-left: 1px solid $color-grey-400;
              }
            }

            th {
              position: relative;
              padding: 12px $column-gap 12px $column-gap;
              border-left: 1px solid $color-grey-400;
              box-shadow: inset 0 1px 0 $color-grey-400,
                inset 0 -1px 0 $color-grey-500;
              text-align: center;
              font: $font-regular-10;
              color: $color-grey-500;

              &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 3px;
                background: white;
                top: -3px;
                left: -1px;
              }
            }
          }
        }

        tbody {
          color: $color-black;

          &>*:nth-child(odd) {
            background-color: $color-grey-300;
          }

          &>*:nth-child(even) {
            background-color: $color-white;
          }

          tr {
            height: 40px;
            border-bottom: 1px solid $color-grey-500;

            td {
              padding: 12px $column-gap 12px $column-gap;
              border-left: 1px solid $color-grey-400;
              border-right: 1px solid $color-grey-400;
              font: $font-regular-10;
              vertical-align: center;
              text-align: center;

              &:not(:first-child):not(:last-child).min-width-unset {
                min-width: unset;
              }

              .table__grouped-row {
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                text-align: left;

                .table__grouping-img {
                  width: 24px;
                  height: 24px;
                  cursor: pointer;
                }
              }
            }

            .none-left-border {
              @extend .min-width-unset;
              border-left: none;

              * {
                margin: 0 auto;
              }
            }

            .none-right-border {
              border-right: none;
            }
          }
        }

        tr.table__row {
          &_red {
            background-color: $color-red-100;
          }

          &_green {
            background-color: $color-green-200;

            .green {
              background-color: $color-green-300;
            }
          }
        }

        .table__data-container {
          min-width: 50px;
          max-width: fit-content;
          margin: 0 auto;
          display: flex;
          align-items: flex-end;
          min-height: 20px;
        }


        .table_editable__cell-grouping-controls {
          height: 110%;
          width: 28px;
          max-width: 28px;
          padding: 12px 6px 12px 0;

          img {
            cursor: pointer;
          }
        }
      }
    }
  }

  .editable-table-footer {
    .editable-table-footer__info {
      flex-wrap: wrap;

      .editable-table-footer__info-element {
        display: flex;
        gap: 20px;
      }
    }
  }
}

.table-input {
  width: 100%;
  height: max-content;
  border: none;
  background: transparent;
  font: $font-regular-10;

  input {
    margin-top: 2px;
    min-height: 18px;
    padding-bottom: 0 !important;
    border-bottom: none !important;
    border-bottom: 2px solid $color-black !important;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
  }

  &:not(&_has-value) {
    margin-bottom: 7px;
  }

  &_has-value {
    border-bottom-color: transparent;
    border-image: none;

    input {
      border-bottom-color: transparent !important;
    }
  }

  &__error-message {
    position: static;
  }
}

@media (max-width: $media-max-width-md) {
  .table-wrapper {
    .editable-table-footer {
      gap: 0;
      margin-top: 20px;

      .editable-table-footer__title {}

      .editable-table-footer__info {
        margin-top: 30px;

        .editable-table-footer__info-element {
          display: grid;
          grid-template-columns: 100px 100px;
          grid-column-gap: 20px;
          gap: 20px;
          font: $font-regular-12;

          input {
            font: $font-regular-12;
          }
        }
      }
    }
  }
}