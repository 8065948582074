@import "src/variables";

.back-btn {
  padding: 40px 80px 100px;
}

@media (max-width: $media-max-width-lg) {
  .back-btn {
    padding: 54px 16px 100px;
  }
}

.section-delimiter {
  height: 0;
  margin: -1px 0;
  border: 1px solid #D9D9D9;
}

.invoice-section {

  .invoice-section__text-subsection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, max-content);
    grid-gap: 40px;
    grid-column-gap: 50px;
  }

  .invoice-section__doc-subsection {
    margin-top: 40px;
  }
}

@media (max-width: $media-max-width-lg) {
  .invoice-section {

    .invoice-section__text-subsection {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: repeat(2, max-content);
      grid-gap: 20px;
      grid-column-gap: 20px;
    }
  }
}

.production-section {
  .production-section__info {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
  }

  .production-section__table {
    margin-top: 40px;
  }
}

.loaded-docs-section {
  .loaded-docs-section__docs {
    display: flex;
    gap: 120px;

    .loaded-docs-section__doc {
      .loaded-docs-section__doc-title {
        margin-bottom: 46px;
        font-weight: normal;
      }
    }
  }

  .loaded-docs-section__docs-tree {
    padding-top: 34px;
    padding-left: 40px;
  }
}

@media (max-width: $media-max-width-lg) {
  .loaded-docs-section {
    .loaded-docs-section__docs {
      gap: 37px;

      .loaded-docs-section__doc {
        .loaded-docs-section__doc-title {
          margin-bottom: 10px;
        }
      }
    }

    .loaded-docs-section__docs-tree {
      padding-top: 30px;
      padding-left: 0;
    }
  }
}

.eapplication-section {
  display: flex;
  align-items: center;
  gap: 40px;
}

@media (max-width: $media-max-width-lg) {
  .eapplication-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
