@import "src/variables";

.modal-container {
  max-width: 720px;
}

.success-settings-modal {
  display: flex;
  flex-direction: column;

  .success-settings-modal__title {
    margin-bottom: 50px;
    font: $font-regular-32;
  }

  .success-settings-modal__subtitle {
    font: $font-regular-20;
  }

  .success-settings-modal__notification {
    margin-top: 30px;
    margin-bottom: 0;
    background-color: $color-red-100;
  }

  .success-settings-modal__progress {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    gap: 20px;

    & > p {
      font-size: 12px;
    }
  }

  .close-btn {
    margin: 50px auto 0 0;
  }
}

@media (max-width: $media-max-width-lg) {
  .success-settings-modal {
    .success-settings-modal__title {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 500;
      font: $font-regular-16;
    }

    .success-settings-modal__subtitle {
      font: $font-regular-12;
    }

    .success-settings-modal__status-progress {
      margin-top: 30px;
    }
  }
}
