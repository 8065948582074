@import "src/variables";

.summary {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;

  .summary__delimiter {
    width: 100%;
    border-color: $color-black;
  }

  .summary__info-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .summary__summary-title {
      font: $font-regular-16;
    }

    .summary__summary-info {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .summary__summary-element {
        display: flex;
        gap: 20px;

        p {
          white-space: nowrap;
          color: $color-grey-500;
        }

        .summary__summary-value {
          color: $color-black;
        }

        input {
          max-width: 100px;
          padding-bottom: 6px;
          background-color: transparent;
        }
      }
    }
  }
}

@media (max-width: $media-max-width-md) {
  .summary {
    .summary__info-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      gap: 30px;

      .summary__summary-info {

        .summary__summary-element {
          display: grid;
          grid-template-columns: 100px 100px;
          grid-column-gap: 20px;
          gap: 20px;
          font: $font-regular-12;

          input {
            font: $font-regular-12;
          }
        }
      }
    }
  }
}