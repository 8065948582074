@import "src/variables";

.saved-zsn {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: $color-grey-300;

  .saved-zsn__body {
    display: flex;
    gap: 40px;

    .saved-zsn__info {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      width: 100%;
    }
  }

  .saved-zsn__text-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 195px;
    height: max-content;
    font: $font-regular-12;

    p {
      color: $color-grey-600;

      &:last-child {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: $media-max-width-md) {
  .saved-zsn__text-item {
    width: 70px;
    font: $font-regular-10;

    p {
      &:last-child {
        font-size: 12px;
      }
    }
  }

  .saved-zsn {
    .saved-zsn__body {
      gap: 55px;

      .saved-zsn__info {
        order: 0
      }
    }
  }
}